import React from "react";
import enhanceMessage from "./enhanceMessage";
import styled from "styled-components";
import Button from "components/Common/GreenButton";


const DialogWrapper = styled.div`
    position: absolute;
    top: calc(-70vh);
    left: -70px;
    width: 400px;
`;

const Dialog = styled.div`
    width: calc(100% - 64px);
    max-width: 444px;
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;
    margin: 32px;
    position: relative;
    overflow-y: auto;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    border-radius: 4px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
`;

const DialogTitle = styled.div`
    flex: 0 0 auto;
    margin: 0;
    padding: 16px 24px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
`;

const DialogContentText = styled.p`
    color: rgba(0, 0, 0, 0.54);
`;

const DialogContent = styled.div`
    flex: 1 1 auto;
    padding: 8px 24px;
`;

const DialogActions = styled.div`
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: flex-end;
`;


function ErrorDialog({ dismissError, error }) {
  const { message, code } = error || {};
  const enhancedMessage = enhanceMessage(message, code);
    if (!error) return null;

  return (
      <DialogWrapper>
        <Dialog>
          <DialogTitle>ERROR</DialogTitle>
          <DialogContent>
            <DialogContentText>{enhancedMessage}</DialogContentText>
            {Boolean(code) && (
              <pre>
                <code>Error Code: {code}</code>
              </pre>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => dismissError()} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </DialogWrapper>
  );
}

export default ErrorDialog;
