import React from "react";
import styled from "styled-components";

const DisconnectedMessage = styled.span`
  margin-top: 20px;
`;

const DisconnectedInfo = (props) => {

    return (
        <p>
            {props.reasons.network && (
                <DisconnectedMessage>
                    The other person has a problem with their internet connection. Please wait for them to reconnect.
                </DisconnectedMessage>
            )}

            {props.reasons.leftActive && (
                <DisconnectedMessage>
                    The other person left the room.
                </DisconnectedMessage>
            )}
            {props.reasons.changeDevice && (
                <DisconnectedMessage>
                    The other person is changing their camera or microphone.
                </DisconnectedMessage>
            )}
            {props.reasons.publishProblems && (
                <DisconnectedMessage>
                    {props.reasons.publishProblems}
                </DisconnectedMessage>
            )}
        </p>
    )
};

export default DisconnectedInfo
