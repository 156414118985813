import React, { useState } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import { getDataOptions } from "utils/helpers";
import SettingsIcon from "assets/img/setting-icon.png";
import Settings from "../Footer/Settings";
import { ControlItem } from "../Footer/styled";


const SettingsWrapper = styled.div`
    display: ${pr => pr.visible ? "block" : "none"};
    position: absolute;
    bottom: 70px;
    left: 0;
`;

const ReactTooltipStyled1 = styled(ReactTooltip)`
    z-index: 1 !important;
    background-color: #444444 !important;
    color: #ffffff !important;
    border-radius: 15px !important;
`;

const Menu = ({ isScreenSharing }) => {
    const [settingsMenuIsOpen, setSettingsMenuIsOpen] = useState(false);
    const menuToggle = () => setSettingsMenuIsOpen(isOpen => !isOpen);

    return (
        <>
            {!settingsMenuIsOpen &&
                <ReactTooltipStyled1
                    arrowColor="transparent"
                    id={"SettingsController"}
                    place="top"
                    effect="solid"
                    className="tooltip1"
                    multiline={true}
                >
                    Settings
                </ReactTooltipStyled1>
            }
            <ControlItem
                data-tip data-for={"SettingsController"}
                icon={SettingsIcon}
                data-trackable={
                    getDataOptions({
                        view: "SettingsController",
                        type: "button",
                        label: "SettingsIcon"
                    })
                }
                onClick={menuToggle}
            />

            <SettingsWrapper visible={settingsMenuIsOpen}>
                <Settings
                    isScreenSharing={isScreenSharing}
                    closeControl={() => setSettingsMenuIsOpen(false)}
                    settingsMenuIsOpen={settingsMenuIsOpen}
                />
            </SettingsWrapper>
        </>
    );
};

export default Menu;
