import {
    SELECTED_AUDIO_INPUT_KEY,
    SELECTED_AUDIO_OUTPUT_KEY,
    SELECTED_VIDEO_INPUT_KEY
} from "../components/ZoomVideo/utils/videoConstants";

const deviceIdStorage = () => {
    const viCoName = SELECTED_VIDEO_INPUT_KEY;
    const auCoName = SELECTED_AUDIO_INPUT_KEY;
    const auOutName = SELECTED_AUDIO_OUTPUT_KEY;

    const hasStoredValue = (name) => {
        return !!window.localStorage.getItem(name);
    };

    const getVideoDeviceId = () => getDeviceIdByName(viCoName);

    const setVideoDeviceId = (value) => {
        setDeviceIdByName(viCoName, value);
    };

    const getAudioDeviceId = () => getDeviceIdByName(auCoName);

    const setAudioDeviceId = (value) => {
        setDeviceIdByName(auCoName, value);
    };

    const getAudioOutputDeviceId = () => getDeviceIdByName(auOutName);

    const setAudioOutputDeviceId = (value) => {
        setDeviceIdByName(auOutName, value);
    };

    const getDeviceIdByName = (name) => {
        return window.localStorage.getItem(name);
    };

    const setDeviceIdByName = (name, value) => {
        window.localStorage.setItem(name, value);
    };

    return {
        getAudioOutputDeviceId,
        setAudioOutputDeviceId,
        getVideoDeviceId,
        setVideoDeviceId,
        getAudioDeviceId,
        setAudioDeviceId,
        hasStoredValue
    }
};


export default deviceIdStorage()
