import Video from "twilio-video";
import { useCallback, useState } from "react";
import DetectRTC from "detectrtc";

import { SELECTED_AUDIO_INPUT_KEY, SELECTED_VIDEO_INPUT_KEY } from "../../../constants";
import { getDeviceInfo, isPermissionDenied } from "../../../utils";

import store from "store";
import {
    setAudioTrackAction,
    setStopAllTracks,
    setVideoTrackAction,
    showAccessGrantedErrorBlock,
    showErrorInfoBlock
} from "store/twilio/actions";
import { setMicOnlyModeAction } from "store/users/actions";
import { errorReasons } from "utils/constants";
import useVideoConfig from "../../../utils/useVideoConfig/useVideoConfig";

// roomController this.openTok.getDevices()
const checkDevices = async ({videoInputDevices, audioInputDevices}) => {
  const {micOnly} = store.getState().controller.currentEvent;
  if ((!DetectRTC.isWebsiteHasWebcamPermissions || !videoInputDevices.length) && micOnly) {
    store.dispatch(setMicOnlyModeAction());
  }
  if (
      ((!audioInputDevices.length || !videoInputDevices.length) && !micOnly) ||
      (!audioInputDevices.length && micOnly)) {
        store.dispatch(showErrorInfoBlock(errorReasons['REASON_ACCESS_DENIED']));
  }
};



export default function useLocalTracks() {
  const videoConfig = useVideoConfig();
  const [audioTrack, setAudioTrack] = useState();
  const [videoTrack, setVideoTrack] = useState();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);

  const getLocalAudioTrack = useCallback((deviceId) => {
    const options = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return Video.createLocalAudioTrack(options).then(newTrack => {
      setAudioTrack(newTrack);
      return newTrack;
    });
  }, []);

  const getLocalVideoTrack = useCallback(async () => {
    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

    const { videoInputDevices } = await getDeviceInfo();

    const hasSelectedVideoDevice = videoInputDevices.some(
      device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    const options = {
      ...videoConfig,
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
    };

    return Video.createLocalVideoTrack(options).then(newTrack => {
      setVideoTrack(newTrack);
      return newTrack;
    });
  }, []);

  const removeLocalAudioTrack = useCallback(() => {
    console.log('removeLocalAudioTrack', {audioTrack});
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    console.log('removeLocalVideoTrack', {videoTrack});
    if (videoTrack && videoTrack.processor) {
      videoTrack.removeProcessor(videoTrack.processor);
    }
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const getAudioAndVideoTracks = useCallback(async () => {
    const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = await getDeviceInfo();

    if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve();
    if (isAcquiringLocalTracks || audioTrack || videoTrack) return Promise.resolve();

    setIsAcquiringLocalTracks(true);

    const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

    const hasSelectedAudioDevice = audioInputDevices.some(
      device => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    );
    const hasSelectedVideoDevice = videoInputDevices.some(
      device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    await checkDevices({videoInputDevices, audioInputDevices});

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied('camera');
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone');

    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied;
    const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied;

    const localTrackConstraints = {
      video: shouldAcquireVideo && {
        ...videoConfig,
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
      },
      audio:
        shouldAcquireAudio &&
        (hasSelectedAudioDevice ? { deviceId: { exact: selectedAudioDeviceId } } : hasAudioInputDevices),
    };

    return Video.createLocalTracks(localTrackConstraints)
      .then(tracks => {
        const newVideoTrack = tracks.find(track => track.kind === 'video');
        const newAudioTrack = tracks.find(track => track.kind === 'audio');

        const immediatelyStopTracks = store.getState().twilio.immediatelyStopTracks;
        if (immediatelyStopTracks) {
          if (newAudioTrack) {
            newAudioTrack.stop();
            setAudioTrack(undefined);
          }
          if (newVideoTrack && newVideoTrack.processor) {
            newVideoTrack.removeProcessor(newVideoTrack.processor);
          }
          if (newVideoTrack) {
            newVideoTrack.stop();
            setVideoTrack(undefined);
          }
          store.dispatch(setStopAllTracks(false));
          return;
        }

        store.dispatch(setVideoTrackAction(newVideoTrack));
        store.dispatch(setAudioTrackAction(newAudioTrack));

        if (newVideoTrack) {
          setVideoTrack(newVideoTrack);
          // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
          // in cases where the user's video is disabled.
          window.localStorage.setItem(
            SELECTED_VIDEO_INPUT_KEY,
            newVideoTrack.mediaStreamTrack.getSettings().deviceId || ''
          );
        }
        if (newAudioTrack) {
          setAudioTrack(newAudioTrack);
        }

        // These custom errors will be picked up by the MediaErrorSnackbar component.
        if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
          store.dispatch(showAccessGrantedErrorBlock(errorReasons['REASON_ACCESS_DENIED']));
          const error = new Error();
          error.name = 'NotAllowedError';
          throw error;
        }

        if (isCameraPermissionDenied && !store.getState().controller.currentEvent.micOnly) {
          store.dispatch(showAccessGrantedErrorBlock(errorReasons['REASON_ACCESS_DENIED']));
          const error = new Error();
          error.name = 'CameraPermissionsDenied';
          throw error;
        }

        if (isMicrophonePermissionDenied) {
          store.dispatch(showAccessGrantedErrorBlock(errorReasons['REASON_ACCESS_DENIED']));
          const error = new Error();
          error.name = 'MicrophonePermissionsDenied';
          throw error;
        }
      })
      .finally(() => setIsAcquiringLocalTracks(false));
  }, [audioTrack, videoTrack, isAcquiringLocalTracks]);

  const localTracks = [audioTrack, videoTrack].filter(track => track !== undefined);

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  };
}
