import React, { useCallback } from "react";
import { connect } from "react-redux";
import styled from "styled-components";


import { endCallAction, partnerClickedEndCallAction, setEndCallClickedAction } from "store/room/actions";
import { getDataOptions } from "utils/helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { partnerConnectedAction } from "../../../../store/room/actions";
import { setDisconnectedAction, switchFishbowlViewAction } from "../../../../store/controller/actions";
import IndependentGreenButton from "../../../Common/IndependentGreenButton";

export const EndCallButton = styled(IndependentGreenButton)`
  position: relative;
  left: -70px;
  text-align: left;
  margin-top: 0;
  display: block;
  height: 40px;
  width: fit-content;
  font-size: 16px;
  color: #CCCCCC;
  padding: 0 12px;
  line-height: 1;
  border-radius: 3px;
  background-color: #A32722;
  border: 1px solid #A32722;
  
  &:hover {
    color: #ffffff;
    background-color: #821F1B;
    border-color: #821F1B;
  }
  &:focus {
    color: #ffffff;
    background-color: #611714;
    border-color: #611714;
  }
  &:active {
    color: #ffffff;
    background-color: #611714;
    border-color: #611714;
  }
  &:disabled {
    background-color: #b3b3b3;
    border-color: #b3b3b3;
    cursor: not-allowed;
  }
`;

const CallController = props => {
    const {zmClient} = props;

    const endCall = useCallback(async () => {
        if (props.currentSession.roomNumber) {
            window.sessionStorage.setItem(`clicked-end-call`, props.currentSession.roomNumber);
        }

        await zmClient.leave();

        props.setEndCallClickedAction(true);
        props.partnerClickedEndCallAction();
        props.endCallAction();
    }, [zmClient]);

    if (props.showNextPartnerBioPopup) return null;

    return (
        <EndCallButton
            data-trackable={
                getDataOptions({
                    view: 'CallController',
                    type: 'button',
                    label: 'CallEndIcon'
                })
            }
            onClick={endCall}
        >
            <FontAwesomeIcon icon='sign-out-alt' style={{ fontSize: 15 }}/>
            &nbsp;End Call
        </EndCallButton>
    )
};

export default connect(
    store => ({
        zmClient: store.zoom.zmClient,
        currentSession: store.controller.currentSession,
        appMode: store.controller.appMode,
        showNextPartnerBioPopup: store.controller.showNextPartnerBioPopup,
        eventId: store.controller.currentEvent.eventId,
    }),
    {
        setEndCallClickedAction,
        endCallAction,
        partnerClickedEndCallAction,
        partnerConnectedAction,
        setDisconnectedAction,
        switchFishbowlViewAction
    }
)(CallController)
