import React, { useEffect, useState } from "react";
import ChatIcon from "assets/img/chat-icon.png";
import { ControlItem } from "./styled";
import { getDataOptions } from "utils/helpers";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

const ANIMATION_DURATION = 700;

const ReactTooltipStyled1 = styled(ReactTooltip)`
  z-index: 1 !important;
  position: absolute;
  background-color: #444444 !important;
  color: #ffffff !important;
  border-radius: 15px !important;
  padding: 5px 12px !important;
`;

const ChatController = props => {
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const { isChatWindowOpen, setIsChatWindowOpen, conversation } = useChatContext();
    const { setIsBackgroundSelectionOpen } = useVideoContext();

    const toggleChatWindow = () => {
        setIsChatWindowOpen(!isChatWindowOpen);
        setIsBackgroundSelectionOpen(false);
    };

    useEffect(() => {
        if (shouldAnimate) {
            setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
        }
    }, [shouldAnimate]);

    useEffect(() => {
        if (conversation && !isChatWindowOpen) {
            const handleNewMessage = () => {
                console.info('[Hooks] conversation.on(\'messageAdded\')');
                return setShouldAnimate(true);
            };
            conversation.on('messageAdded', handleNewMessage);
            return () => {
                conversation.off('messageAdded', handleNewMessage);
            };
        }
    }, [conversation, isChatWindowOpen]);


    return (
        <>
            <ReactTooltipStyled1
                arrowColor="transparent"
                id={'ChatController'}
                place="top"
                effect="solid"
                className="tooltip1"
                multiline={true}
            >
                Text Chat
            </ReactTooltipStyled1>
            <ControlItem
                disabled={!conversation}
                data-tip data-for={'ChatController'}
                icon={ChatIcon} bgSize="50%"
                data-trackable={
                    getDataOptions({
                        view: 'ChatController',
                        type: 'button',
                        label: 'ChatIcon'
                    })
                }
                onClick={toggleChatWindow}
            />
        </>
    )
};

export default ChatController;
