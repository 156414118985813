import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Styles from "./styles";
import socketModule from "utils/socketModule";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faTimes } from "@fortawesome/free-solid-svg-icons";
import PaperPlaneRight from "assets/img/paper-plane-right.svg";

const ALLOWED_FILE_TYPES =
    'audio/*, image/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document .xslx, .ppt, .pdf, .key, .svg, .csv';

const ChatInput = ({isChatWindowOpen, conversation, currentEvent, currentSession, currentUser, isWebinar, isPreview = false, addMessage = () => {}}) => {
    const [messageBody, setMessageBody] = useState('');
    const [isSendingFile, setIsSendingFile] = useState(false);
    const [fileSendError, setFileSendError] = useState(null);
    const textInputRef = useRef(null);
    const fileInputRef = useRef(null);
    const isValidMessage = /\S/.test(messageBody);

    useEffect(() => {
        if (isChatWindowOpen) {
            // When the chat window is opened, we will focus on the text input.
            // This is so the user doesn't have to click on it to begin typing a message.
            textInputRef && textInputRef.current && textInputRef.current.focus();
        }
    }, [isChatWindowOpen]);

    const handleChange = (event) => {
        setMessageBody(event.target.value);
    };

    // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
    const handleReturnKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // just add line break; so complicated because ctrlKey by default doesn't add line break
            if (e.shiftKey || e.ctrlKey) {
                setMessageBody(`${messageBody}\n`);
            } else {
                handleSendMessage(messageBody);
            }
        }
    };

    const handleSendMessage = message => {
        if (isValidMessage) {
            if (isPreview) {
                const messageData = {
                    author: isWebinar ? currentUser.userId : `${currentUser.firstName} ${currentUser.lastName} | ${currentUser.userId}`,
                    type: 'text',
                    body: message.trim(),
                    dateCreated: moment().toISOString(),
                    attributes: {
                        photo: currentUser.profilePhoto,
                        initials: `${currentUser.firstName.substring(0, 1)}${currentUser.lastName.substring(0, 1)}`.toUpperCase(),
                        color: currentUser.profileColorHex,
                        userId: currentUser.userId
                    }
                };
                addMessage(messageData);
            } else {
                conversation.sendMessage(message.trim());
                socketModule.socket.emit('chat.new_message', {
                    eventId: currentEvent.eventId,
                    roomNumber: currentSession.roomNumber,
                    userId: currentUser.userId,
                    message: message.trim()
                });
            }
            setMessageBody('');
        }
    };

    const handleSendFile = (event) => {
        const file = event.target && event.target.files ? event.target.files[0] : null;
        if (file) {
            const formData = new FormData();
            formData.append('userfile', file);
            setIsSendingFile(true);
            setFileSendError(null);
            conversation
                .sendMessage(formData)
                .catch(e => {
                    if (e.code === 413) {
                        setFileSendError('File size is too large. Maximum file size is 150MB.');
                    } else {
                        setFileSendError('There was a problem uploading the file. Please try again.');
                    }
                    console.log('Problem sending file: ', e);
                })
                .finally(() => {
                    setIsSendingFile(false);
                });
        }
    };

    const onSendMessage = (e) => {
        e.preventDefault();
        handleSendMessage(messageBody);
    }

    return (
        <Styles.InputWrapper isWebinar={isWebinar} isPreview={isPreview}>
            {Boolean(fileSendError) && <Styles.ErrorPopup>
                <FontAwesomeIcon icon={faTimes} onClick={() => setFileSendError(null)}/>
                {fileSendError || ''}</Styles.ErrorPopup>}
            <Styles.Textarea
                isWebinar={isWebinar}
                ref={textInputRef}
                onChange={handleChange}
                onKeyPress={handleReturnKeyPress}
                value={messageBody}
                placeholder={'Type a message...'}
                isPreview={isPreview}
            />
            <input
                ref={fileInputRef}
                type="file"
                style={{display: 'none'}}
                onChange={handleSendFile}
                value={''}
                accept={ALLOWED_FILE_TYPES}
                disabled={isPreview}
            />
            {isWebinar
                ? <div style={{position: 'absolute', right: 0, display: 'flex'}}>
                    {!isPreview && <Styles.Button
                        fileButton={isWebinar}
                        onClick={() => fileInputRef && fileInputRef.current && fileInputRef.current.click()}
                        disabled={isSendingFile}
                        isWebinar={isWebinar}
                    >
                        {
                            isSendingFile
                                ? <Styles.Spiner/>
                                : <FontAwesomeIcon icon={faPaperclip}/>
                        }
                    </Styles.Button>}

                    {isWebinar && <Styles.Button onClick={onSendMessage} isWebinar={isWebinar}>
                        <Styles.Img src={PaperPlaneRight} alt='send'/>
                    </Styles.Button>}
                </div>
                : <Styles.Button
                    onClick={() => fileInputRef && fileInputRef.current && fileInputRef.current.click()}
                    disabled={isSendingFile}
                >
                    {
                        isSendingFile
                            ? <Styles.Spiner/>
                            : <FontAwesomeIcon icon={faPaperclip}/>
                    }
                </Styles.Button>
            }

        </Styles.InputWrapper>
    )
};

export default connect(
    store => {
        return {
            currentEvent: store.controller.currentEvent,
            currentSession: store.controller.currentSession,
            currentUser: store.users.currentUser
        }
    }
)(ChatInput);
