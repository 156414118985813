import React from "react";
import styled from "styled-components";

const OuterContainer = styled.div`
    width: 2em;
    height: 2em;
    padding: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
`;

const InnerContainer = styled.div`
    display: flex;
    align-items: flex-end;

    & div {
        width: 2px;
        margin-right: 1px;

        &:not(:last-child) {
            border-right: none;
        }

    ,
    }

,
`;

const STEP = 3;
const BARS_ARRAY = [0, 1, 2, 3, 4];

export default function NetworkQualityLevel({ networkQualityLevel }) {
    if (networkQualityLevel === null) return <OuterContainer/>;

    return (
        <OuterContainer>
            <InnerContainer>
                {BARS_ARRAY.map(level => (
                    <div
                        key={level}
                        style={{
                            height: `${STEP * (level + 1)}px`,
                            background: networkQualityLevel > level ? "white" : "rgba(255, 255, 255, 0.2)"
                        }}
                    />
                ))}
            </InnerContainer>
        </OuterContainer>
    );
}
