import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { getProfileColor, getUserInitials } from "utils/helpers";
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";


//  .OT_root .OT_publisher .OT_fit-mode-cover
const Container = styled.div`
    position: relative;
    display: flex;
    cursor: ${pr => pr.cursorPointer ? "pointer" : "inherit"};
    align-items: center;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
    overflow: hidden;
    background: black;
    box-sizing: border-box;
    border: 1px solid rgb(128 128 128 / 50%);

    & video {
        filter: none;
            //transform: ${pr => pr.isLocalParticipant ? "scale(-1, 1)" : "none"};
        //object-fit: contain !important;
    }

    @media screen and (max-width: 737px), screen and (max-height: 500px) {
        margin-bottom: 0;
        font-size: 12px;
    }

    .innerContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .infoContainer {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        background: transparent;
        top: 0;

        @media screen and (max-width: 737px), screen and (max-height: 500px) {
            bottom: 0;
            top: auto;
        }
    }

    .avatar-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background: black;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        @media screen and (max-width: 737px), screen and (max-height: 500px) {
            & svg {
                transform: scale(0.7);
            }
        }
    }

    .reconnectingContainer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(40, 42, 43, 0.75);
        z-index: 1;
    }

    .screenShareIconContainer {
        background: rgba(0, 0, 0, 0.5);
        padding: 0.18em 0.3em;
        margin-right: 0.3em;
        display: flex;

        & path {
            fill: white;
        }
    }

    .identity {
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 0.18em 0.3em;
        margin: 0;
        display: flex;
        align-items: center;
    }

    .infoRowBottom {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;

        @media screen and (max-width: 737px), screen and (max-height: 500px) {
            bottom: initial;
            top: 15px;
            left: 25px;
        }
    }

    .typeography {
        color: white;
        @media screen and (max-width: 737px), screen and (max-height: 500px) {
            font-size: 0.75rem;
        }
    }

    .avatarContainer {
        text-align: center;
        line-height: 16;
        @media screen and (max-width: 737px), screen and (max-height: 500px) {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;



const UserPhoto = styled.div`
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-size: cover;
    display: block;
    background-image: url(${({ img }) => img || ""});
    background-repeat: no-repeat;
    background-position: center center;
`;


const UserInitials = styled.div`
    background-color: ${({ color }) => color || "#777"};
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 55px;
    line-height: 1.1;
`;

const UserIdentity = styled.div`
    position: relative;;
    padding-left: 28px;
    color: inherit;
`;

const MutedIconBlock = styled.div`
    position: absolute;
    left: 28px;
    top: 0;
`;

const ParticipantInfo = ({
                             participant,
                             onClick,
                             children,
                             isLocalParticipant,
                             otherUser,
                             otherUserB,
                             currentUser,
                             networkQualityLevel,
                             isCurrentUserSpeaking
                         }) => {
    const displayName = participant?.displayName;
    const bVideoOn = participant?.bVideoOn;
    const userIdentity = participant?.userIdentity;
    const isVideoConnect = participant?.isVideoConnect;
    const isLocal = isLocalParticipant;
    const isVideoEnabled = isVideoConnect;
    const isVideoSwitchedOff = !bVideoOn;
    const { horizontablePosition, connectionStatus } = useVideoContext();
    const user = [otherUser, otherUserB, currentUser].find(u => userIdentity === u.userId);
    const identity = displayName;
    const isParticipantReconnecting = connectionStatus === 'connecting' || participant?.isInFailover;

    return (
        <Container
            cursorPointer={Boolean(onClick)}
            onClick={onClick}
            horizontablePosition={horizontablePosition}
        >
            <div className={"infoContainer"}>
                <div className={"infoRowBottom"}>
                    <NetworkQualityLevel networkQualityLevel={networkQualityLevel}/>
                    <span className={"identity"}>
                        <MutedIconBlock>
                          <AudioLevelIndicator
                              participant={participant}
                              isCurrentUserSpeaking={isCurrentUserSpeaking}
                          />
                        </MutedIconBlock>
                        <UserIdentity className={"typeography"}>
                          {identity}
                            {isLocal && " (You)"}
                        </UserIdentity>
                    </span>
                </div>
            </div>
            <div className={"innerContainer"}>
                {(!isVideoEnabled || isVideoSwitchedOff) && (
                    <div className="avatar-container">
                        {user ? user.profilePhoto
                                ? <UserPhoto img={user.profilePhoto} crossOrigin="anonymous"/>
                                : <UserInitials color={getProfileColor(user)}>{getUserInitials(user)}</UserInitials>
                            : <AvatarIcon/>}
                    </div>
                )}
                {isParticipantReconnecting && (
                  <div className={'reconnectingContainer'}>
                    <div className={'typeography'}>
                      Reconnecting...
                    </div>
                  </div>
                )}
                {children}
            </div>
        </Container>
    );
};
export default connect(store => ({
    otherUser: store.users.otherUser,
    otherUserB: store.users.otherUserB,
    currentUser: store.users.currentUser
}))(ParticipantInfo);
