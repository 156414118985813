import React, { useEffect } from "react";
import styled from "styled-components";

import ParticipantList from "../ParticipantList/ParticipantList";
import ChatWindowComponent from "../ChatWindow/ChatWindowComponent";
import Footer from "../Footer";
import { useUnmount } from "../../hooks/useUnmount/useUnmount";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { useShare } from "../../hooks/useShare/useShare";
import NoChromeStopSharingButton from "../NoChromeStopSharingButton/NoChromeStopSharingButton";
import store from "../../../../store";
import BackgroundSelectionDialog from "../BackgroundSelectionDialog/BackgroundSelectionDialog";


const totalMobileSidebarHeight = 90 + 8 * 2 + 2;

const Container = styled.div`
    position: relative;
    height: 100%;
    display: grid;

    @media screen and (max-width: 737px), screen and (max-height: 500px) {
        grid-template-columns: 1fr;
        grid-template-rows: calc(100% - ${totalMobileSidebarHeight}px);
    }
`;

const Room = () => {
    const { zoom: {zmClient} } = store.getState();
    const { mediaContext: {mediaStream}, startCameraAndMicrophone } = useVideoContext();
    const { activeSharingId, isStartedSelfShare} = useShare(zmClient, mediaStream);

    useUnmount(() => {
        const isCurrentUserStartedVideo = zmClient.getCurrentUserInfo()?.bVideoOn;
        const isCurrentUserStartedScreen = zmClient.getCurrentUserInfo()?.sharerOn;

        if (mediaStream && isCurrentUserStartedVideo) {
            mediaStream.stopAudio();
            mediaStream.stopVideo();
        }
        if (mediaStream && isCurrentUserStartedScreen) {
            mediaStream.stopShareScreen();
        }

       zmClient.leave();
    });

    useEffect(() => {
        startCameraAndMicrophone();

        const handleBeforeUnload = async () => {
            await zmClient.leave();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, []);

    return (
        <Container>
            <ParticipantList
                activeSharingId={activeSharingId}
                isStartedSelfShare={isStartedSelfShare}
            />
            <ChatWindowComponent />
            <NoChromeStopSharingButton />
            <BackgroundSelectionDialog />
            <Footer isScreenSharing={!!activeSharingId} />
        </Container>
    );
};

export default Room;

