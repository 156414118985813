export const DEFAULT_VIDEO_CONSTRAINTS = {
  width: 1280,
  height: 720,
  frameRate: 30,
};

export const BACKGROUND_FILTER_VIDEO_CONSTRAINTS = {
  width: 640,
  height: 480,
  frameRate: 24,
};

export const P2P_VIDEO_CONSTRAINTS = {
  width: 1280,
  height: 720,
  frameRate: 24
};

export const P2P_MOBILE_VIDEO_CONSTRAINTS = {
  width: 640,
  height: 480,
  frameRate: 24
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'meetaway_audio_device';
export const SELECTED_AUDIO_OUTPUT_KEY = 'meetaway_audio_out_device';
export const SELECTED_VIDEO_INPUT_KEY = 'meetaway_video_device';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'meetaway-selectedBackgroundSettings';
