import { useEffect, useState } from "react";

export default function useMainParticipantId({ participants, selectedParticipant, currentUser }) {
  const localParticipant = participants.find(({userIdentity})=> userIdentity === currentUser.userId);
  const otherParticipants = participants.filter(({userIdentity}) => userIdentity && userIdentity !== currentUser.userId);
  const otherParticipant = otherParticipants[0];

  const [mainParticipantId, setMainParticipantId] = useState(localParticipant?.userId);

  useEffect(() => {
    const mainParticipant = selectedParticipant || otherParticipant || localParticipant;
    if (mainParticipant && mainParticipant.userId && mainParticipant.userId !== mainParticipantId) {
      setMainParticipantId(mainParticipant.userId);
    }
  }, [selectedParticipant, otherParticipant, localParticipant, mainParticipantId]);

  return mainParticipantId;
}
