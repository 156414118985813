export const PARTICIPANT_VIDEO_ID = 'ZOOM_WEB_SDK_PARTICIPANT_VIDEO';
export const SHARE_CANVAS_ID = 'ZOOM_WEB_SDK_SHARER_CANVAS';
export const SELF_SHARE_CANVAS = 'ZOOM_WEB_SDK_SELF_SHARE_CANVAS';
export const SELF_SHARE_VIDEO = 'ZOOM_WEB_SDK_SELF_SHARE_VIDEO';
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'meetaway-selectedBackgroundSettings';

export const Video_90P = 0;
export const Video_180P = 1;
export const Video_360P = 2;
export const Video_720P = 3;
export const Video_1080P = 4;

export const SELECTED_AUDIO_INPUT_KEY = 'meetaway_audio_device';
export const SELECTED_AUDIO_OUTPUT_KEY = 'meetaway_audio_out_device';
export const SELECTED_VIDEO_INPUT_KEY = 'meetaway_video_device';
