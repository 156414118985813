import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import AvatarIcon from "../../icons/AvatarIcon";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";
import ScreenShareIcon from "../../icons/ScreenShareIcon";

import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import useParticipants from "../../hooks/useParticipants/useParticipants";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { getProfileColor, getUserInitials } from "utils/helpers";


//  .OT_root .OT_publisher .OT_fit-mode-cover
const Container = styled.div`
  position: relative;
  display: ${pr => pr.hideParticipant ? 'none' : 'flex'};
  cursor: ${pr => pr.cursorPointer ? 'pointer' : 'inherit'};
  align-items: center;
  height: 200px;
  overflow: hidden;
  margin-bottom: 15px;
  background: black;

  & video {
    filter: none;
    //transform: ${pr => pr.isLocalParticipant ? 'scale(-1, 1)' : 'none'};
    //object-fit: contain !important;
  }

  @media screen and (max-width: 737px), screen and (max-height: 500px) {
    height: ${pr => pr.fullScreen || pr.horizontablePosition ? '100%' : '50%'};
    max-height: 100%;
    width: 100%;
    margin-bottom: 0;
    font-size: 12px;
  }

  .innerContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .infoContainer {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background: transparent;
    top: 0;

    @media screen and (max-width: 737px), screen and (max-height: 500px) {
      bottom: 0;
      top: auto;
    }
  }

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    @media screen and (max-width: 737px), screen and (max-height: 500px) {
      & svg {
        transform: scale(0.7);
      }
    }
  }

  .reconnectingContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(40, 42, 43, 0.75);
    z-index: 1;
  }

  .screenShareIconContainer {
    background: rgba(0, 0, 0, 0.5);
    padding: 0.18em 0.3em;
    margin-right: 0.3em;
    display: flex;

    & path {
      fill: white;
    }
  }

  .identity {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0.18em 0.3em;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .infoRowBottom {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;

    @media screen and (max-width: 737px), screen and (max-height: 500px) {
      bottom: 0;
      left: 25px;
      top: auto;
    }
  }

  .typeography {
    color: white;
    @media screen and (max-width: 737px), screen and (max-height: 500px) {
      font-size: 0.75rem;
    }
  }

  .avatarContainer {
    text-align: center;
    line-height: 16;
    @media screen and (max-width: 737px), screen and (max-height: 500px) {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const NetworkContainer = styled.div`
  @media screen and (max-width: 737px), screen and (max-height: 500px) {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.18em 0.3em;
  }
`;

const UserPhoto = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-size: cover;
  display: block;
  background-image: url(${({img}) => img || ''});
  background-repeat: no-repeat;
  background-position: center center;
`;


const UserInitials = styled.div`
  background-color: ${({color}) => color || '#777'};
  width: 180px;
  height: 180px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 55px;
  line-height: 1.1;
`;

const ParticipantInfo = ({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
  otherUser,
  otherUserB,
  currentUser
}) => {
  const [loaded, setLoaded] = useState(false);

  const publications = usePublications(participant);
  const participants = useParticipants();
  const { horizontablePosition } = useVideoContext();

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video');

  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);

  const audioTrack = useTrack(audioPublication);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const user = [otherUser, otherUserB, currentUser].find(u => participant.identity === `${u.firstName} ${u.lastName} | ${u.userId}`);
  const [identity] =  participant.identity.split(' | ');

  useEffect(() => {
    setLoaded(false);
    setTimeout(() => setLoaded(true), 2000);
  }, [participants]);

  return (
    <Container hideParticipant={hideParticipant} cursorPointer={Boolean(onClick)}
      data-private="true"
      onClick={onClick}
      data-cy-participant={participant.identity}
      horizontablePosition={horizontablePosition}
      fullScreen={participants.length === 1}
      isLocalParticipant={isLocalParticipant}
    >
      <div className={'infoContainer'}>
        <NetworkContainer>
          <NetworkQualityLevel participant={participant} />
        </NetworkContainer>
        <div className={'infoRowBottom'}>
          {isScreenShareEnabled && (
            <span className={'screenShareIconContainer'}>
              <ScreenShareIcon />
            </span>
          )}
          <span className={'identity'}>
            {audioTrack && <AudioLevelIndicator audioTrack={audioTrack} />}
            <span className={'typeography'}>
              {identity}
              {isLocalParticipant && ' (You)'}
            </span>
          </span>
        </div>
      </div>
      <div className={'innerContainer'}>
        {(!isVideoEnabled || isVideoSwitchedOff) && (
          <div className="avatar-container">
            {loaded && (
              <>
                {user ? user.profilePhoto
                        ? <UserPhoto img={user.profilePhoto} />
                        : <UserInitials color={getProfileColor(user)}>{getUserInitials(user)}</UserInitials>
                    : <AvatarIcon />}
              </>
            )}
          </div>
        )}
        {isParticipantReconnecting && (
          <div className={'reconnectingContainer'}>
            <div className={'typeography'}>
              Reconnecting...
            </div>
          </div>
        )}
        {children}
      </div>
    </Container>
  );
}
export default connect(store => ({
  otherUser: store.users.otherUser,
  otherUserB: store.users.otherUserB,
  currentUser: store.users.currentUser
}))(ParticipantInfo)
