import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import store from "store";
import { setIsBackgroundSelectionOpen } from "store/zoom/actions";
import BackgroundSelectionHeader from "./BackgroundSelectionHeader/BackgroundSelectionHeader";
import BackgroundThumbnail from "./BackgroundThumbnail/BackgroundThumbnail";
import AbstractThumb from "../../images/thumb/Abstract.jpg";
import BohoHomeThumb from "../../images/thumb/BohoHome.jpg";
import BookshelfThumb from "../../images/thumb/Bookshelf.jpg";
import CoffeeShopThumb from "../../images/thumb/CoffeeShop.jpg";
import ContemporaryThumb from "../../images/thumb/Contemporary.jpg";
import CozyHomeThumb from "../../images/thumb/CozyHome.jpg";
import DesertThumb from "../../images/thumb/Desert.jpg";
import FishingThumb from "../../images/thumb/Fishing.jpg";
import FlowerThumb from "../../images/thumb/Flower.jpg";
import KitchenThumb from "../../images/thumb/Kitchen.jpg";
import ModernHomeThumb from "../../images/thumb/ModernHome.jpg";
import NatureThumb from "../../images/thumb/Nature.jpg";
import OceanThumb from "../../images/thumb/Ocean.jpg";
import PatioThumb from "../../images/thumb/Patio.jpg";
import PlantThumb from "../../images/thumb/Plant.jpg";
import SanFranciscoThumb from "../../images/thumb/SanFrancisco.jpg";
import useBackgroundSettings from "../../hooks/useBackgroundSettings/useBackgroundSettings";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";


const Drawer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 100%;
  background: white;
  z-index: 126;

  .thumbnailContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    overflow-y: auto;
  }
`;

const imageNames = [
    "Abstract",
    "Boho Home",
    "Bookshelf",
    "Coffee Shop",
    "Contemporary",
    "Cozy Home",
    "Desert",
    "Fishing",
    "Flower",
    "Kitchen",
    "Modern Home",
    "Nature",
    "Ocean",
    "Patio",
    "Plant",
    "San Francisco"
];

const images = [
    AbstractThumb,
    BohoHomeThumb,
    BookshelfThumb,
    CoffeeShopThumb,
    ContemporaryThumb,
    CozyHomeThumb,
    DesertThumb,
    FishingThumb,
    FlowerThumb,
    KitchenThumb,
    ModernHomeThumb,
    NatureThumb,
    OceanThumb,
    PatioThumb,
    PlantThumb,
    SanFranciscoThumb
];

const BackgroundSelectionDialog = () => {
    const { mediaContext: {mediaStream}, startVideo } = useVideoContext();
    const { currentEvent, isBackgroundSelectionOpen, zmClient } = useSelector(
        (state) => ({
            currentEvent: state.controller.currentEvent,
            isBackgroundSelectionOpen: state.zoom.isBackgroundSelectionOpen,
            zmClient: state.zoom.zmClient
        })
    );

    const handlerUpdateVB = useCallback(async (imageUrl) => {
        await mediaStream?.updateVirtualBackgroundImage(imageUrl);
    }, [mediaStream]);

    const handlerClearVB = useCallback(async () => {
        await mediaStream?.stopVideo();
        await startVideo(mediaStream);
    }, [mediaStream, startVideo]);

    const handlerStartVB = useCallback(async (imageUrl) => {
        await mediaStream?.stopVideo();
        await startVideo(mediaStream, imageUrl);
    }, [mediaStream, startVideo]);

    const [backgroundSettings, onChangeBackground] = useBackgroundSettings({
        isStartedVideo: zmClient.getCurrentUserInfo()?.bVideoOn,
        handlerUpdateVB, handlerClearVB, handlerStartVB
    });

    return (
        Boolean(isBackgroundSelectionOpen) &&
        <Drawer>
            <BackgroundSelectionHeader onClose={() => store.dispatch(setIsBackgroundSelectionOpen(false))}/>
            <div className={'thumbnailContainer'}>
                <BackgroundThumbnail
                    thumbnail={'none'}
                    name={'None'}
                    backgroundSettings={backgroundSettings}
                    setBackgroundSettings={onChangeBackground}
                />
                <BackgroundThumbnail
                    thumbnail={'blur'}
                    name={'Blur'}
                    backgroundSettings={backgroundSettings}
                    setBackgroundSettings={onChangeBackground}
                />
                {currentEvent.defaultVirtualBackground &&
                    <BackgroundThumbnail
                        thumbnail={'event'}
                        name={'Event'}
                        imagePath={currentEvent.defaultVirtualBackground}
                        backgroundSettings={backgroundSettings}
                        setBackgroundSettings={onChangeBackground}
                    />
                }
                {images.map((image, index) => (
                    <BackgroundThumbnail
                        thumbnail={'image'}
                        name={imageNames[index]}
                        index={index}
                        imagePath={image}
                        key={image}
                        backgroundSettings={backgroundSettings}
                        setBackgroundSettings={onChangeBackground}
                    />
                ))}
            </div>
        </Drawer>
    );
}

export default memo(BackgroundSelectionDialog);
