export const errorReasons = {
    REASON_NO_DEVICE: 'noDevice', // fires when user denied access to camera or mic in the getmedia() prompt
    REASON_WEBCAM_IS_BLOCKED: 'webcamBlocked',
    REASON_NO_CONNECTION: 'noConnection', //fires error within session connection
    REASON_DISCONNECTED: 'clientDisconnected', //fires by disconnect session or publisher
    REASON_BROWSER_RELOADED: 'browserReloaded',
    REASON_DISCONNECTED_END_CALL: 'sessionDisconnected', //fires by end CAll button
    REASON_NETWORK_UNSTABLE: 'networkUnstable',
    REASON_NETWORK_DISCONNECTED: 'networkDisconnected', //fires when network lost
    REASON_NETWORK_DISCONNECTED_OTHER: 'networkDisconnectedOther', //fires when network lost of publisher
    REASON_DISCONNECTED_OTHER: 'otherPersonDisconnected', //fires when other person disconnected
    REASON_ACCESS_DENIED: 'accessDenied', //fires when access to camera or mic
    REASON_WEBCAM_ACCESS_DENIED: 'webcamAccessDenied', //fires when access to camera denied
    REASON_QUALITY: 'quality', //fires when camera stream disables cause low connection
    REASON_QUALITY_ME: 'qualityMe', //low connection cause me
    REASON_QUALITY_OTHER: 'qualityOther', //low connection cause other
    REASON_RECONNECTING: 'reconnecting',
};
