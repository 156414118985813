import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { PARTICIPANT_VIDEO_ID, SELF_SHARE_CANVAS, SELF_SHARE_VIDEO, SHARE_CANVAS_ID } from "../../utils/videoConstants";
import ParticipantInfo from "../ParticipantInfo/ParticipantInfo";
import Loader from "../../../Loader";

const Container = styled.div`
    display: ${pr => pr.isVideoSwitchedOff ? "none" : "block"};
    width: 100%;
    height: 100%;

    video-player-container {
        width: 100%;
        min-width: 100%;
        height: 100%;
        min-height: 100%;
    }

    video-player {
        width: 100%;
        min-width: 100%;
        height: 100%;
        min-height: 100%;
        aspect-ratio: 16/9;
    }
`;

const ShareView = styled.canvas`
    margin: auto 0;
    width: 100%;
    min-width: 100%;
    height: auto;
`;

const SelfCanvasShareView = styled.canvas`
    display: ${pr => pr.isStartedSelfShare ? "block" : "none"};
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    aspect-ratio: 16/9;
`;
const SelfVideoShareView = styled.video`
    display: ${pr => pr.isStartedSelfShare ? "block" : "none"};
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    aspect-ratio: 16/9;
`;

const ShareViewContainer = styled.div`
    display: flex;
    height: 100%;
    min-height: 100%;
`;


const Participant = ({
                         participant,
                         networkQualityLevel,
                         onClick,
                         isLocalParticipant,
                         isScreenSharing,
                         isStartedSelfShare,
                         isStartShareScreenWithVideoElement,
                         isMainParticipant,
                         isCurrentUserSpeaking,
                         isLoading
                     }) => {
    const userId = participant?.userId;
    const isVideoSwitchedOff = !participant?.bVideoOn;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => setLoading(false), 800);
    }, [isMainParticipant]);

    const clickHandler = () => {
        if (onClick) {
            setLoading(true);
            setTimeout(() => setLoading(false), 800);
            onClick();
        }
    };

    return (
        <ParticipantInfo
            networkQualityLevel={networkQualityLevel}
            participant={participant}
            onClick={isMainParticipant ? null : clickHandler}
            isLocalParticipant={isLocalParticipant}
            isCurrentUserSpeaking={isCurrentUserSpeaking}
        >
            {(isLoading || loading) &&
                <Loader
                    showLoading={true}
                    style={{
                        position: "absolute",
                        paddingBottom: 40,
                        fontSize: 20,
                        zIndex: 1,
                        color: "#ffffff"
                    }}
                    backgroundColor={"#000000"}
                />
            }
            {isLocalParticipant &&
                <>
                    <SelfCanvasShareView
                        isStartedSelfShare={isStartedSelfShare && !isStartShareScreenWithVideoElement}
                        id={`${SELF_SHARE_CANVAS}`}
                    />
                    <SelfVideoShareView
                        isStartedSelfShare={isStartedSelfShare && isStartShareScreenWithVideoElement}
                        id={`${SELF_SHARE_VIDEO}`}
                    />
                </>
            }

            {isScreenSharing &&
                <ShareViewContainer>
                    <ShareView id={`${SHARE_CANVAS_ID}_${userId}`}/>
                </ShareViewContainer>
            }

            <Container
                id={`${PARTICIPANT_VIDEO_ID}_${userId}`}
                isVideoSwitchedOff={isVideoSwitchedOff || loading || isScreenSharing}
            >
                <video-player-container/>
            </Container>
        </ParticipantInfo>
    );
};

export default Participant;
