import styled from "styled-components";
import chatIcon from "assets/img/chat-icon.png";

const Wrapper = styled.div`
  color: #fff;
  width: 250px;
  border-radius: 8px 8px 4px 4px;
  background: rgba(0, 0, 0, 1);
  box-shadow: 2px 1px 5px 3px black;
  overflow: hidden;
  position: fixed;
  bottom: ${pr => pr.isWebinar ? '90px' : '25px'};
  right: 20px;
  z-index: 999;
`;

const ChatHeader = styled.div`
  padding: 8px 10px;
  position: relative;
  display: flex;
  border-radius: 8px 8px 0 0;
  background-color: #000;
  overflow: hidden;
  border-bottom: 0;

  svg {
    cursor: pointer;
    margin-left: auto;
  }

  &:before {
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 4px;
    background: url(${chatIcon}) no-repeat center center / cover;
  }
`;

const ChatBodyWrapper = styled.div`
  position: relative;
  height: 280px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const EmptyChatDecorator = styled.div`
  position: absolute;
  background: url(${chatIcon}) no-repeat center center;
  background-size: 90px 90px;
  background-position: 82px 50px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ChatBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 5px;
`;

const MessagesWrap = styled.div`
  max-height: ${({isWebinar}) => isWebinar ? '100%' : '200px'};
  height: ${({isWebinar}) => isWebinar ? 'calc(100% - 50px)' : '200px'};
  overflow: auto;
  padding-right: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #595959;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;


export default {
    Wrapper,
    ChatHeader,
    ChatBodyWrapper,
    EmptyChatDecorator,
    ChatBody,
    MessagesWrap
}
