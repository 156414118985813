import React, { useEffect, useState } from "react";
import Tooltip from "react-tooltip";
import styled from "styled-components";
import { connect } from "react-redux";

import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import AvatarIcon from "../../icons/AvatarIcon";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";

import useIsRecording from "../../hooks/useIsRecording/useIsRecording";
import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { getProfileColor, getUserInitials } from "utils/helpers";
import useParticipants from "../../hooks/useParticipants/useParticipants";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  // & video {
  //   transform: ${pr => pr.isLocalParticipant ? 'scale(-1, 1)' : 'none'};
  // }

  .identity {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0.1em 0.3em 0.1em 0;
    display: inline-flex;

    & svg {
      margin-left: 0.3em;
    }

    margin-right: 0.4em;
    align-items: center;
  }
  
  .userInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
  }

  .infoContainer {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
  }

  .reconnectingContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(40, 42, 43, 0.75);
    z-index: 1;
  }

  grid-area: 1 / 1 / 2 / 3;
  @media screen and (max-width: 737px), screen and (max-height: 500px) {
    grid-area: 1 / 1 / 3 / 3;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    height: 100%;
    width: 100vw;
    z-index: 1;

    & svg {
      transform: scale(2);
    }

    @media screen and (max-width: 737px), screen and (max-height: 500px) {
      & svg {
        transform: none;
      }
    }
  }

  .recordingIndicator {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0.1em 0.3em 0.1em 0;
    font-size: 1.2rem;
    height: 28px;

    @media screen and (max-width: 737px), screen and (max-height: 500px) {
      bottom: auto;
      right: 0;
      top: 0;
    }
  }

  .circle {
    height: 12px;
    width: 12px;
    background: red;
    border-radius: 100%;
    margin: 0 0.6em;
    animation: 1.25s pulsate ease-out infinite;
  }

  @keyframes pulsate {
    0% {
      background: #A90000;
    }
    50% {
      background: #f00;
    }
    100% {
      background: #A90000;
    }
  }
`;

const UserInfoContainer = styled.div`
  position: absolute;
  bottom: ${({isRecording}) => isRecording ? '30px' : '0'};
  left: 0;
  display: flex;
`;

const UserAvatar = styled.div`
  background-image: url(${({img}) => img || ''});
  background-size: cover;
  display: block;
  width: 256px;
  height: 256px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  @media screen and (max-width: 737px), screen and (max-height: 500px) {
    width: 80px;
    height: 80px;
  }
`;

const UserInitials = styled.div`
  background-color: ${({color}) => color || '#777'};
  width: 256px;
  height: 256px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 95px;
  line-height: 1.1;
  @media screen and (max-width: 737px), screen and (max-height: 500px) {
    width: 80px;
    height: 80px;
    font-size: 30px;
  }
`;

const  MainParticipantInfo = ({ participant, children, otherUser, otherUserB, currentUser }) => {
  const [loaded, setLoaded] = useState(false);

  const { room } = useVideoContext();
  const localParticipant = room.localParticipant;
  const isLocal = localParticipant === participant;

  const publications = usePublications(participant);

  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video');
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication);

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const isRecording = useIsRecording();
  const participants = useParticipants(true);

  useEffect(() => {
      setLoaded(false);
      setTimeout(() => setLoaded(true), 2000);
  }, [participants]);

  const user = [otherUser, otherUserB, currentUser].find(u => participant.identity === `${u.firstName} ${u.lastName} | ${u.userId}`);
  const [identity] =  participant.identity.split(' | ');

  return (
    <Container
      data-private="true"
      data-cy-participant={participant.identity}
      isLocalParticipant={isLocal}
    >
      <div className={'infoContainer'}>
        <UserInfoContainer isRecording={isRecording}>
          <div className={'identity'}>
            {audioTrack && <AudioLevelIndicator audioTrack={audioTrack} />}
            <div style={{ color: "inherit" }}>
              {identity}
              {isLocal && ' (You)'}
              {screenSharePublication && ' - Screen'}
            </div>
          </div>
          <NetworkQualityLevel participant={participant} />
        </UserInfoContainer>
        {isRecording && (
          <>
            <div className={'recordingIndicator'} data-tip data-for="recording">
              <div className={'circle'} />
              <div style={{ color: "inherit" }}>
                Recording
              </div>
            </div>
            <Tooltip id="recording" type={'dark'} arrowColor={'transparent'} delayShow={1000}>
              <span>All participants' audio and video is currently being recorded. Visit the app settings to stop recording.</span>
            </Tooltip>
          </>
        )}
      </div>

      {(!isVideoEnabled || isVideoSwitchedOff) &&
          <div className="avatar-container">
            {loaded && (
                <>
                  {user ? user.profilePhoto
                          ? <UserAvatar img={user.profilePhoto}/>
                          : <UserInitials color={getProfileColor(user)}>{getUserInitials(user)}</UserInitials>
                      : <AvatarIcon/>}
                </>
            )}
          </div>
      }

      {isParticipantReconnecting && (
        <div className={'reconnectingContainer'}>
          <div style={{ color: 'white' }}>
            Reconnecting...
          </div>
        </div>
      )}
      {children}
    </Container>
  );
}

export default connect(store => ({
  otherUser: store.users.otherUser,
  otherUserB: store.users.otherUserB,
  currentUser: store.users.currentUser
}))(MainParticipantInfo);
