import React from "react";
import styled from "styled-components";

import GreenButton from "components/Common/GreenButton";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import DetectRTC from "detectrtc";

import Footer from "../../components/Footer";

const ScreenShareBanner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  z-index: 8;
  padding: 0 15px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);

  p {
    color: white;
    font-size: 16px;
    margin-right: 10px;
  }
`;

const Button = styled(GreenButton)`
  padding: 12px 16px;
`;

export default function MenuBar() {
    const { isSharingScreen, toggleScreenShare } = useVideoContext();

    return (
        <>
            {isSharingScreen && !DetectRTC.browser.isChrome && (
                <ScreenShareBanner>
                    <p>You are sharing your screen</p>
                    <Button onClick={toggleScreenShare}>Stop Sharing</Button>
                </ScreenShareBanner>
            )}
            <Footer/>
        </>
    );
}
