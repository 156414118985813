import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { isSupported } from "@twilio/video-processors";
import { VideoRoomMonitor } from "@twilio/video-room-monitor";

import settingsIcon from "assets/img/setting-icon.png";
import { isMobile } from "../../utils";

import { updateRecordingRules } from "../../../../store/twilio/actions";
import useChatContext from "../../hooks/useChatContext/useChatContext";
//import useIsRecording from "../../hooks/useIsRecording/useIsRecording";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useFlipCameraToggle from "../../hooks/useFlipCameraToggle/useFlipCameraToggle";
import useDevices from "../../hooks/useDevices/useDevices";
import useMediaStreamTrack from "../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import { SELECTED_AUDIO_INPUT_KEY, SELECTED_VIDEO_INPUT_KEY } from "../../constants";
import {
    faChartBar,
    faImage,
    faInfoCircle,
    faMicrophone,
    faSync,
    faTimes,
    faTv,
    faVideo,
    faVolumeUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useActiveSinkId from "../../utils/useActiveSinkId/useActiveSinkId";
import { getUnique } from "../../../../utils/helpers";
import useVideoConfig from "../../utils/useVideoConfig/useVideoConfig";

const whiteBorder = `1px solid gray`;

export const Wrapper = styled.div`
  color: #fff;
  width: ${isMobile ? '216px' : '298px'};
  background: rgba(0, 0, 0, 1);
  border-radius: 8px 8px 4px 4px;
  box-shadow: 2px 1px 5px 3px black;
  overflow: hidden;
`;

export const Header = styled.div`
  padding: 8px 10px 8px 15px;
  position: relative;
  display: flex;
  border-radius: 8px 8px 0 0;
  border-bottom: ${whiteBorder};
  background-color: #000;
  overflow: hidden;
  color: #b1b0b0;

  svg {
    cursor: pointer;
    margin-left: auto;
  }
  
  // &:before {
  //   display: inline-block;
  //   content: '';
  //   width: 20px;
  //   height: 20px;
  //   margin-right: 4px;
  //   background: url(${settingsIcon}) no-repeat center center / cover;
  // }
`;

export const CloseButton = styled.button`
  margin-left: auto;
  padding: 0;
  background-color:transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
`;

const MenuItem = styled.div`
  box-sizing: border-box;
  padding: 6px 10px 6px 18px;
  color: #b1b0b0;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

const SectionTitle = styled.div`
  color: ${pr => pr.isGray ? "#6e6d6d" : '#b1b0b0'};
  font-size: 14px;
  padding: 0 10px 10px 18px;
`;

const Section = styled.div`
  padding-top: 7px;
  padding-bottom: 10px;

  &:not(:last-child) {
    border-bottom: ${whiteBorder};
  }
`;

const SectionItem = styled.div`
  position: relative;
  padding-left: 52px;
  padding-right: 5px;
  color: #b1b0b0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  
  &:before {
    color: #FFFFFF;
    content: ${pr => pr.selected ? "'✓'" : 'none'};
    position: absolute;
    top: 50%;
    left: 35px;
    transform: translateY(-50%);
  }

  &:hover {
    color: #fff;
  }
`;

const Settings = ({ roomType, closeControl, setAboutOpen, isFetching, currentUser, isUseVirtualBackground }) => {
    const videoConfig = useVideoConfig();
    const { room, setIsBackgroundSelectionOpen, isSharingScreen, toggleScreenShare } = useVideoContext();
    const { setIsChatWindowOpen } = useChatContext();
    const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();
    //const isRecording = useIsRecording();
    const roomState = room ? room.state : null;
    const { videoInputDevices } = useDevices();
    const { localTracks } = useVideoContext();
    const [activeSinkId, setActiveSinkId] = useActiveSinkId();
    const isAdmin = currentUser.isAdmin;

    // useEffect(()=>{
    //     if (!isAdmin) {
    //         VideoRoomMonitor.openMonitor();
    //         const monitorContainer = document.getElementById('TwilioVideoRoomMonitorContainer');
    //         if (monitorContainer) monitorContainer.setAttribute('style', 'display: none;');
    //     }
    // }, [isAdmin]);

    // VIDEO
    const localVideoTrack = localTracks.find(track => track.kind === 'video');
    const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
    const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
        window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
    );
    const localVideoInputDeviceId = mediaStreamTrack ? mediaStreamTrack.getSettings().deviceId || storedLocalVideoDeviceId : null;
    const isReconnecting = roomState === "reconnecting";
    const replaceTrack = (newDeviceId) => {
        // Here we store the device ID in the component state. This is so we can re-render this component display
        // to display the name of the selected device when it is changed while the users camera is off.
        setStoredLocalVideoDeviceId(newDeviceId);
        window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
        if (localVideoTrack) {
            localVideoTrack.restart({
                ...videoConfig,
                deviceId: { exact: newDeviceId },
            });
        }
    };

    // AUDIO
    const { audioInputDevices, audioOutputDevices } = useDevices();
    const localAudioTrack = localTracks.find(track => track.kind === 'audio');
    const mediaAudioStreamTrack = useMediaStreamTrack(localAudioTrack);
    const localAudioInputDeviceId = mediaAudioStreamTrack && mediaAudioStreamTrack.getSettings ? mediaAudioStreamTrack.getSettings().deviceId : null;

    const replaceAudioTrack = (newDeviceId) => {
        window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
        if (localAudioTrack) localAudioTrack.restart({ deviceId: { exact: newDeviceId } });
    };

    return (
        <Wrapper>
            <Header>
                Settings
                <FontAwesomeIcon icon={faTimes} onClick={closeControl} />
            </Header>
            {((!isSharingScreen && !isMobile) || (isSupported && isUseVirtualBackground) || flipCameraSupported) &&
            <Section>
                {!isSharingScreen && !isMobile && (
                    <MenuItem
                        disabled={isReconnecting}
                        onClick={toggleScreenShare}
                    >
                        <FontAwesomeIcon icon={faTv} color={'white'} />
                        &nbsp;&nbsp;Share screen
                    </MenuItem>
                )}
                {!!(isSupported && isUseVirtualBackground) && (
                    <MenuItem onClick={() => {
                        setIsBackgroundSelectionOpen(true);
                        setIsChatWindowOpen(false);
                        closeControl();
                    }}>
                        <FontAwesomeIcon icon={faImage} color={'white'} />
                        &nbsp;&nbsp;Backgrounds
                    </MenuItem>
                )}
                {flipCameraSupported && (
                    <MenuItem
                        disabled={flipCameraDisabled}
                        onClick={toggleFacingMode}
                    >
                        <FontAwesomeIcon icon={faSync} color={'white'} />
                        &nbsp;&nbsp;Flip Camera
                    </MenuItem>
                )}
            </Section>
            }
            {/*{roomType !== "peer-to-peer" && roomType !== "go" && (*/}
            {/*    <MenuItem*/}
            {/*        disabled={isFetching}*/}
            {/*        onClick={() => {*/}
            {/*            closeControl();*/}
            {/*            if (isRecording) {*/}
            {/*                updateRecordingRules(room.sid, [{ type: "exclude", all: true }]);*/}
            {/*            } else {*/}
            {/*                updateRecordingRules(room.sid, [{ type: "include", all: true }]);*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        {isRecording ? "Stop" : "Start"} Recording*/}
            {/*    </MenuItem>*/}
            {/*)}*/}

            <Section>
                <SectionTitle isGray={true}>
                    <FontAwesomeIcon icon={faVideo} color={"#6e6d6d"} />
                    &nbsp;&nbsp;Webcam
                </SectionTitle>
                {videoInputDevices && videoInputDevices.length ? videoInputDevices.map((device) => (
                    <SectionItem
                        selected={device.deviceId === localVideoInputDeviceId}
                        key={device.deviceId}
                        onClick={() => {
                            if (device.deviceId !== localVideoInputDeviceId) {
                                replaceTrack(device.deviceId)
                            } else {
                                closeControl();
                            }
                        }}
                    >
                        {device.label || 'Unnamed device'}
                    </SectionItem>
                )) : (
                    <SectionItem selected={true}>
                        Unnamed device
                    </SectionItem>
                )}
                <br/>
                <SectionTitle isGray={true}>
                    <FontAwesomeIcon icon={faMicrophone} color={"#6e6d6d"} />
                    &nbsp;&nbsp;Microphone
                </SectionTitle>
                {audioInputDevices && audioInputDevices.length ? getUnique(audioInputDevices, "deviceId").map((device) => (
                    <SectionItem
                        selected={device.deviceId === localAudioInputDeviceId}
                        key={device.deviceId}
                        onClick={() => {
                            if (device.deviceId !== localAudioInputDeviceId) {
                                replaceAudioTrack(device.deviceId)
                            } else {
                                closeControl();
                            }
                        }}
                    >
                        {device.label || 'Unnamed device'}
                    </SectionItem>
                )) : (
                    <SectionItem selected={true}>
                        Unnamed device
                    </SectionItem>
                )}
                <br/>
                <SectionTitle isGray={true}>
                    <FontAwesomeIcon icon={faVolumeUp} color={"#6e6d6d"} />
                    &nbsp;&nbsp;Speakers
                </SectionTitle>
                {audioOutputDevices && audioOutputDevices.length ? getUnique(audioOutputDevices, "deviceId").map((device) => (
                    <SectionItem
                        selected={device.deviceId === activeSinkId}
                        key={device.deviceId}
                        onClick={() => {
                            if (device.deviceId !== activeSinkId) {
                                setActiveSinkId(device.deviceId)
                            } else {
                                closeControl();
                            }
                        }}
                    >
                        {device.label || 'Unnamed device'}
                    </SectionItem>
                )) : (
                    <SectionItem selected={true}>
                        Unnamed device
                    </SectionItem>
                )}
            </Section>

            {isAdmin &&
                <>
                    <MenuItem onClick={() => {
                        VideoRoomMonitor.toggleMonitor();
                        closeControl();
                    }}>
                        <FontAwesomeIcon icon={faChartBar} color={'white'} />
                        &nbsp;&nbsp;Room Monitor
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setAboutOpen(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} color={'white'} />
                        &nbsp;&nbsp;About
                    </MenuItem>
                </>
            }
        </Wrapper>
    );

};

const mapStateToProps = (store) => ({
    isFetching: store.twilio.isFetching,
    roomType: store.twilio.roomType,
    currentUser: store.users.currentUser,
    isUseVirtualBackground: store.system.virtualBackground
});

const mapDispatchToProps = {
    updateRecordingRules,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
