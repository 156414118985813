import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { AudioChangeAction, MutedSource } from "@zoom/videosdk";

import store from "store";
import { getDataOptions } from "utils/helpers";
import audioOnIcon from "assets/img/audio-on.png";
import audioOffIcon from "assets/img/audio-off.png";
import { ControlItem } from "./styled";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { dispatchError, showErrorInfoBlock } from "../../../../store/zoom/actions";
import { errorReasons } from "../../../../utils/constants";
import { connect } from "react-redux";

const ReactTooltipStyled1 = styled(ReactTooltip)`
  z-index: 1 !important;
  background-color: #444444 !important;
  color: #ffffff !important;
  border-radius: 15px !important;
`;

const AudioController = ({
                             isStartedAudio, setIsStartedAudio, initializedAudio,
                             isMuted, setIsMuted, dispatchError, zmClient
}) => {
    const { mediaContext: {mediaStream}, startAudio } = useVideoContext();
    const isAudioEnabled = isStartedAudio && !isMuted;

    const onMicrophoneClick = useCallback(async () => {
        if (isStartedAudio) {
            if (isMuted) {
                await mediaStream?.unmuteAudio();
            } else {
                await mediaStream?.muteAudio();
            }
        } else {
            try {
                await startAudio(mediaStream);
            } catch (e) {
                if (e.reason === 'USER_FORBIDDEN_MICROPHONE') {
                    store.dispatch(showErrorInfoBlock(errorReasons['REASON_ACCESS_DENIED']));
                }
                console.error(e);
                dispatchError(e);
            }
            setIsStartedAudio(true);
        }
    }, [mediaStream, isStartedAudio, isMuted]);

    const onHostAudioMuted = useCallback((payload) => {
        const { action, source } = payload;
        if (action === AudioChangeAction.Join) {
            setIsStartedAudio(true);
        } else if (action === AudioChangeAction.Leave) {
            setIsStartedAudio(false);
        } else if (action === AudioChangeAction.Muted) {
            setIsMuted(true);
            if (source === MutedSource.PassiveByMuteOne) {
                console.info('Host muted you');
            }
        } else if (action === AudioChangeAction.Unmuted) {
            setIsMuted(false);
            if (source === 'passive') {
                console.info('Host unmuted you');
            }
        }
    }, []);

    useEffect(() => {
        zmClient.on('current-audio-change', onHostAudioMuted);

        return () => {
            zmClient.off('current-audio-change', onHostAudioMuted);
        };
    }, [zmClient, onHostAudioMuted]);

    return (
        <>
            <ReactTooltipStyled1
                arrowColor="transparent"
                id={'AudioController'}
                place="top"
                effect="solid"
                className="tooltip1"
                multiline={true}
            >
                Microphone
            </ReactTooltipStyled1>
            <ControlItem
                disabled={!initializedAudio}
                data-tip data-for={'AudioController'}
                icon={isAudioEnabled ? audioOnIcon : audioOffIcon}
                data-trackable={
                    getDataOptions({
                        view: 'AudioController',
                        type: 'button',
                        label: isAudioEnabled ? 'audioOnIcon' : 'audioOffIcon'
                    })
                }
                onClick={onMicrophoneClick}
            />
        </>
    )
};

const mapStateToProps = (store) => ({
    zmClient: store.zoom.zmClient,
});
const mapDispatchToProps = {
    dispatchError,
};
export default connect(mapStateToProps, mapDispatchToProps)(AudioController);
