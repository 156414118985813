import types from "../constans";

const initialState = {
    participants: [],
    error: null,
    isFetching: false,
    roomType: '',
    needReInitRoom: false,
    roomName: null,
    zmClient: null,
    installedVideoSDK: false,
    isBackgroundSelectionOpen: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.INIT_ZOOM_PARTICIPANTS:
        case types.ADD_ZOOM_PARTICIPANTS:
        case types.UPDATE_ZOOM_PARTICIPANTS:
        case types.DISCONNECT_ZOOM_PARTICIPANTS:
            return {
                ...state,
                participants: action.participants,
            };
        case types.SET_ZOOM_ROOM_NAME:
            return {
                ...state,
                roomName: action.roomName,
            };
        case types.SET_ZOOM_ROOM_TYPE:
            return {
                ...state,
                roomType: action.roomType,
                needReInitRoom: false,
            };
        case types.SET_ZOOM_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
                needReInitRoom: false,
            };
        case types.SET_ZOOM_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case types.SET_ZOOM_CLIENT:
            return {
                ...state,
                zmClient: action.zmClient,
                installedVideoSDK: true,
            };
        case types.SET_ZOOM_IS_BACKGROUND_SECTION_OPEN:
            return {
                ...state,
                isBackgroundSelectionOpen: action.isOpen,
            };

        default: return state
    }
}
