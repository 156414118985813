import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cookies from "js-cookie";
import moment from "moment";
import { BACKGROUND_FILTER_VIDEO_CONSTRAINTS, SELECTED_BACKGROUND_SETTINGS_KEY } from "../../../constants";
import {
    GaussianBlurBackgroundProcessor,
    ImageFit,
    isSupported,
    VirtualBackgroundProcessor
} from "@twilio/video-processors";

import Abstract from "../../../images/Abstract.jpg";
import AbstractThumb from "../../../images/thumb/Abstract.jpg";
import BohoHome from "../../../images/BohoHome.jpg";
import BohoHomeThumb from "../../../images/thumb/BohoHome.jpg";
import Bookshelf from "../../../images/Bookshelf.jpg";
import BookshelfThumb from "../../../images/thumb/Bookshelf.jpg";
import CoffeeShop from "../../../images/CoffeeShop.jpg";
import CoffeeShopThumb from "../../../images/thumb/CoffeeShop.jpg";
import Contemporary from "../../../images/Contemporary.jpg";
import ContemporaryThumb from "../../../images/thumb/Contemporary.jpg";
import CozyHome from "../../../images/CozyHome.jpg";
import CozyHomeThumb from "../../../images/thumb/CozyHome.jpg";
import Desert from "../../../images/Desert.jpg";
import DesertThumb from "../../../images/thumb/Desert.jpg";
import Fishing from "../../../images/Fishing.jpg";
import FishingThumb from "../../../images/thumb/Fishing.jpg";
import Flower from "../../../images/Flower.jpg";
import FlowerThumb from "../../../images/thumb/Flower.jpg";
import Kitchen from "../../../images/Kitchen.jpg";
import KitchenThumb from "../../../images/thumb/Kitchen.jpg";
import ModernHome from "../../../images/ModernHome.jpg";
import ModernHomeThumb from "../../../images/thumb/ModernHome.jpg";
import Nature from "../../../images/Nature.jpg";
import NatureThumb from "../../../images/thumb/Nature.jpg";
import Ocean from "../../../images/Ocean.jpg";
import OceanThumb from "../../../images/thumb/Ocean.jpg";
import Patio from "../../../images/Patio.jpg";
import PatioThumb from "../../../images/thumb/Patio.jpg";
import Plant from "../../../images/Plant.jpg";
import PlantThumb from "../../../images/thumb/Plant.jpg";
import SanFrancisco from "../../../images/SanFrancisco.jpg";
import SanFranciscoThumb from "../../../images/thumb/SanFrancisco.jpg";
import { getInstanceAxios, getVirtualBackground, setVirtualBackground } from "../../../../../utils/helpers";
import config from "../../../../../utils/config";
import useVideoConfig from "../../../utils/useVideoConfig/useVideoConfig";

const imageNames = [
    "Abstract",
    "Boho Home",
    "Bookshelf",
    "Coffee Shop",
    "Contemporary",
    "Cozy Home",
    "Desert",
    "Fishing",
    "Flower",
    "Kitchen",
    "Modern Home",
    "Nature",
    "Ocean",
    "Patio",
    "Plant",
    "San Francisco"
];

const images = [
    AbstractThumb,
    BohoHomeThumb,
    BookshelfThumb,
    CoffeeShopThumb,
    ContemporaryThumb,
    CozyHomeThumb,
    DesertThumb,
    FishingThumb,
    FlowerThumb,
    KitchenThumb,
    ModernHomeThumb,
    NatureThumb,
    OceanThumb,
    PatioThumb,
    PlantThumb,
    SanFranciscoThumb
];

const rawImagePaths = [
    Abstract,
    BohoHome,
    Bookshelf,
    CoffeeShop,
    Contemporary,
    CozyHome,
    Desert,
    Fishing,
    Flower,
    Kitchen,
    ModernHome,
    Nature,
    Ocean,
    Patio,
    Plant,
    SanFrancisco
];

const getImage = (index) => {
    return new Promise((resolve, reject) => {
        if (imageElements.has(index)) {
            return resolve(imageElements.get(index));
        }
        const img = new Image();
        img.onload = () => {
            imageElements.set(index, img);
            resolve(img);
        };
        img.onerror = reject;
        img.src = rawImagePaths[index];
    });
};

let imageElements = new Map();
// Re virtualBackgroundAssets:
// The VideoProcessors load assets dynamically depending on certain browser features.
// You need to serve all the assets and provide the root path so they can be referenced properly.
// These assets can be copied from the dist/build folder which you can add as part of your deployment process.

const isDesktopChrome = /Chrome/.test(navigator.userAgent);
const virtualBackgroundAssets = "/virtualbackground";
let blurProcessor;
let virtualBackgroundProcessor;
let loading;

const useBackgroundSettings = ({ videoTrack, room }) => {
    const isWebcamPage = document.location.href.includes("webcam");
    const videoConfig = useVideoConfig();
    const currentEvent = useSelector((state) => state.controller.currentEvent);
    const isUseVirtualBackground = useSelector((state) => state.system.virtualBackground);
    const localStorageSettings = window.localStorage.getItem(SELECTED_BACKGROUND_SETTINGS_KEY);
    const settings = localStorageSettings ? JSON.parse(localStorageSettings) : { type: "none", index: 0 };

    const setCaptureConstraints = useCallback(async () => {
        const { mediaStreamTrack, processor } = (videoTrack ? videoTrack : {});
        const { type } = settings;
        if (type === "none" && processor) {
            return mediaStreamTrack ? mediaStreamTrack.applyConstraints(videoConfig) : null;
        } else if (type !== "none" && !processor) {
            return mediaStreamTrack ? mediaStreamTrack.applyConstraints(BACKGROUND_FILTER_VIDEO_CONSTRAINTS) : null;
        }
    }, [videoTrack, settings]);

    const onChangeBackground = useCallback((value) => {
        if (currentEvent.defaultVirtualBackground) {
            if (value.type !== "event") {
                setCookieValue(false);
                setBackgroundSettings(value);
                window.localStorage.setItem(SELECTED_BACKGROUND_SETTINGS_KEY, JSON.stringify(value));
            } else {
                setCookieValue(true);
                setBackgroundSettings(value);
            }
        } else {
            setBackgroundSettings(value);
            window.localStorage.setItem(SELECTED_BACKGROUND_SETTINGS_KEY, JSON.stringify(value));
        }
    }, [currentEvent]);

    const setCookieValue = useCallback((isUseVB) => {
        const key = `${currentEvent.eventId}_background_settings`;
        const a = moment(currentEvent.internalEndDateAndTime);
        const b = moment();
        const diffDays = a.diff(b, "days");
        const settings = { isUseVB };
        cookies.set(key, JSON.stringify(settings), { path: "/", expires: diffDays + 2 });
    }, [currentEvent]);

    const [eventBackground, setEventBackground] = useState({
        name: "Event",
        image: currentEvent.defaultVirtualBackground,
        imageFile: null
    });

    const getBackgroundSettings = () => {
        if (currentEvent.defaultVirtualBackground) {
            const key = `${currentEvent.eventId}_background_settings`;
            const eventVBSettings = cookies.get(key);
            if (!eventVBSettings) {
                setCookieValue(true);
                return { type: "event" };
            } else {
                const setting = JSON.parse(eventVBSettings);
                if (setting.isUseVB) {
                    return { type: "event" };
                }

                if (settings.type === "event") {
                    window.localStorage.setItem(SELECTED_BACKGROUND_SETTINGS_KEY, JSON.stringify({
                        type: "none",
                        index: 0
                    }));
                    return { type: "none", index: 0 };
                }
                return settings;
            }
        }

        if (settings.type === "event" && !currentEvent.defaultVirtualBackground) {
            return { type: "none", index: 0 };
        }
        return settings;
    };

    const [backgroundSettings, setBackgroundSettings] = useState(() => {
        return getBackgroundSettings();
    });

    useEffect(() => {
        if (currentEvent && currentEvent.defaultVirtualBackground) {
            setBackgroundSettings(getBackgroundSettings());
        }
    }, [currentEvent]);

    useEffect(() => {
        (async () => {
            if (currentEvent.defaultVirtualBackground) {
                const data = getVirtualBackground(currentEvent.eventId);
                if (!data.file || data.fileUrl !== currentEvent.defaultVirtualBackground) {
                    const response = await getInstanceAxios().get(`${config.NODE_API_URL}/events/virtual-background/${currentEvent.eventId}`);
                    if (response && response.data.status === 200) {
                        setVirtualBackground(response.data.data, currentEvent.defaultVirtualBackground, currentEvent.eventId);
                        setEventBackground({
                            name: "Event",
                            image: currentEvent.defaultVirtualBackground,
                            imageFile: response.data.data
                        });
                    }
                } else {
                    setEventBackground({
                        name: "Event",
                        image: currentEvent.defaultVirtualBackground,
                        imageFile: data.file || null
                    });
                }
            }
        })();
    }, [currentEvent]);

    const removeProcessor = useCallback(() => {
        console.log("removeProcessor");
        if (videoTrack && videoTrack.processor) {
            videoTrack.removeProcessor(videoTrack.processor);
        }
    }, [videoTrack]);

    const addProcessor = useCallback(
        (processor) => {
            if (!videoTrack || videoTrack.processor === processor) {
                return;
            }

            // Prevent unwanted iteration over large arrays
            ["_tflite", "_currentMask", "_dummyImageData", "_masks"].forEach(key => {
                Object.defineProperty(processor, key, {
                    configurable: true,
                    enumerable: false,
                    value: processor[key],
                    writable: true
                });
            });

            console.log("DEBUG:addProcessor", processor);

            removeProcessor();
            videoTrack.addProcessor(processor, {
                inputFrameBufferType: "video",
                outputFrameBufferContextType: "webgl2"
            });
        },
        [videoTrack, removeProcessor]
    );

    const getEventImage = useCallback(
        () => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    resolve(img);
                };
                img.onerror = reject;
                img.src = eventBackground.imageFile;
            });
        },
        [eventBackground]
    );


    useEffect(() => {
        if (!isSupported || !isUseVirtualBackground || (!room && !isWebcamPage) || !currentEvent || !currentEvent.eventId || !videoTrack) {
            return;
        }

        const handleProcessorChange = async () => {
            if (loading) {
                return;
            }
            loading = true;

            console.log("DEBUG:handleProcessorChange");

            if (!blurProcessor) {
                console.log("DEBUG:blurProcessor");
                blurProcessor = new GaussianBlurBackgroundProcessor({
                    assetsPath: virtualBackgroundAssets,
                    // Disable debounce only on desktop Chrome as other browsers either
                    // do not support WebAssembly SIMD or they degrade performance.
                    debounce: !isDesktopChrome
                });
                await blurProcessor.loadModel();
            }
            if (!virtualBackgroundProcessor) {
                console.log("DEBUG:virtualBackgroundProcessor");
                virtualBackgroundProcessor = new VirtualBackgroundProcessor({
                    assetsPath: virtualBackgroundAssets,
                    backgroundImage: await getImage(0),
                    fitType: ImageFit.Cover,
                    // Disable debounce only on desktop Chrome as other browsers either
                    // do not support WebAssembly SIMD or they degrade performance.
                    debounce: !isDesktopChrome
                });
                await virtualBackgroundProcessor.loadModel();
            }

            // Switch to 640x480 dimensions on desktop Chrome or browsers that
            // do not support WebAssembly SIMD to achieve optimum performance.
            const processor = blurProcessor || virtualBackgroundProcessor;
            if (!processor._isSimdEnabled || isDesktopChrome) {
                await setCaptureConstraints();
            }


            if (backgroundSettings.type === "blur") {
                addProcessor(blurProcessor);
            } else if (backgroundSettings.type === "image" && typeof backgroundSettings.index === "number") {
                virtualBackgroundProcessor.backgroundImage = await getImage(backgroundSettings.index);
                addProcessor(virtualBackgroundProcessor);
            } else if (backgroundSettings.type === "event" && currentEvent.defaultVirtualBackground) {
                if (eventBackground.imageFile) {
                    virtualBackgroundProcessor.backgroundImage = await getEventImage();
                    addProcessor(virtualBackgroundProcessor);
                }
            } else {
                removeProcessor();
            }

            return !!(blurProcessor && virtualBackgroundProcessor);
        };

        (async () => {
            const completed = await handleProcessorChange();
            if (completed) loading = false;
        })();

    }, [eventBackground, backgroundSettings, videoTrack, addProcessor, removeProcessor, eventBackground, currentEvent, room, isUseVirtualBackground, setCaptureConstraints]);

    const backgroundConfig = {
        imageNames,
        images,
        eventBackground
    };

    return [backgroundSettings, onChangeBackground, backgroundConfig];
};

export default useBackgroundSettings;
