import React from "react";
import styled from "styled-components";
import FooterControls from "./FooterControls";
import { connect } from "react-redux";

import CallController from "./CallController";
import { isMobile } from "../../utils/util";


const FooterContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  z-index: 105;
  display: flex;
`;

const FooterControlsBlock = styled.div`
  transform: translateX(-50%);
  border-radius: 3px;
  background-color: rgba(0,0,0,0.5);
  border-color: #080808;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Footer = ({
                    joinIsClicked, connected, showControls,
                    showNextPartnerBioPopup, showSurveyPopup, isScreenSharing,
}) => {
    if (!joinIsClicked || !connected || !showControls || showNextPartnerBioPopup || showSurveyPopup) return null;

    return (
        <FooterContainer>
            <FooterControlsBlock>
                <FooterControls isScreenSharing={isScreenSharing} />
            </FooterControlsBlock>
            {!isMobile &&
                <CallController/>
            }
        </FooterContainer>
    );
};

export default connect(
    (store) => {
        return {
            joinIsClicked: store.room.joinIsClicked,
            connected: store.room.connected,
            showControls: store.room.showControls,
            showNextPartnerBioPopup: store.controller.showNextPartnerBioPopup,
            showSurveyPopup: store.controller.showSurveyPopup,
        };
    }
)(Footer);
