import "array-flat-polyfill";
import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import ResizeObserver from "resize-observer-polyfill";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
//import patchDOMForGoogleTranslate from "./utils/patchDOMForGoogleTranslate";
import { setupLogRocket } from "./utils/helpers";
import config from "./utils/config";

//patchDOMForGoogleTranslate();

if (config.SENTRY_DSN) {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.SENTRY_ENVIRONMENT,
        tracesSampleRate: 1.0,
        normalizeDepth: 5,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        allowUrls: [
            /https?:\/\/((video|videodev)\.)?meetaway\.com/
        ],
        denyUrls: [
            /https?:\/\/((video|videodev)\.)?meetaway\.com\/node_modules/
        ],
        beforeSend(event) {
            console.log('DEBAG SENTRY', event);
            return event;
        },
        ignoreErrors: [/ChunkLoadError/, /NotAllowedError/],
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

    });
}

setupLogRocket();

if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}


ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
