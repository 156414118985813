import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import useChatContext from "../../hooks/useChatContext/useChatContext";
import ChatInput from "./ChatInput/ChatInputComponent";
import TextMessage from "./TextMessage/TextMessageComponent";
import MediaMessage from "./MediaMessage/MediaMessageComponent";
import store from "../../../../store";
import Styled from "./styles";

const Chat = ({isWebinar}) => {
    const { isChatWindowOpen, records, setIsChatWindowOpen } = useChatContext();
    const messagesContainerDom = useRef();
    const [formattedMessages, setFormattedMessages] = useState([]);

    useEffect(() => {
        if (isChatWindowOpen) {
            let messages = [];

            records.forEach((item) => {
                if (Array.isArray(item.message)) {
                    item.message.forEach((item) => {
                        messages.push(item)
                    })
                } else {
                    messages.push(item.message)
                }
            });

            setFormattedMessages(messages.map((item) => {
                const data = JSON.parse(item || '{}');
                const body = data.text ?? '';
                const attributes = data.attributes ?? {};

                return {
                    attributes, body,
                    author: attributes?.userId,
                    type: 'text',
                }
            }));

            scrollMessagesDown();
        }
    }, [records, isChatWindowOpen]);


    const scrollMessagesDown = () => {
        const domElem = messagesContainerDom.current;
        if (domElem) {
            domElem.scroll({
                top: parseInt(domElem.scrollHeight)
            });
        }
    };

    return (isChatWindowOpen &&
        <Styled.Wrapper isWebinar={isWebinar}>
            <Styled.ChatHeader>
                <span>Chat</span>
                <FontAwesomeIcon icon={faTimes} onClick={() => {
                    setIsChatWindowOpen(false)
                }} />
            </Styled.ChatHeader>
            <Styled.ChatBodyWrapper>
                {!records.length && <Styled.EmptyChatDecorator />}
                <Styled.ChatBody>
                    <Styled.MessagesWrap ref={messagesContainerDom}>
                        {formattedMessages.map((message, index) => {
                            const isMine = message.author.includes(store.getState().users.currentUser.userId);
                            return (
                                <React.Fragment key={index}>
                                    {message.type === 'text' && <TextMessage message={message} isMine={isMine} />}
                                    {message.type === 'media' && <MediaMessage media={message.media} />}
                                </React.Fragment>
                            )
                        })}
                    </Styled.MessagesWrap>
                    <ChatInput  />
                </Styled.ChatBody>
            </Styled.ChatBodyWrapper>
        </Styled.Wrapper>
    )
};

export default Chat;
