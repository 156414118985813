import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { getSupportFaqsAction } from "store/test/actions";
import { getDataOptions } from "../../utils/helpers";


const StyledMessage = styled.span`
    margin-top: 20px;
`;

const Button = styled.button`
    display: block;
    margin: 50px auto 0;
    color: #fff;
    background-color: #5bc0de;
    border: 1px solid #46b8da;
    font-size: 14px;
    padding: 6px 12px;

    &:hover {
        cursor: pointer;
    }
`;

const ShowTipsBtn = styled.span`
    cursor: pointer;
    color: #5bc0de;

    &:hover {
        text-decoration: underline;
        color: #00AFF4;
    }

    &:active {
        text-decoration: underline;
        color: #0095F4;
    }
`;


const AccessGrantedErrorBlock = ({ reasons, showSupport, currentEvent, getSupportFaqsAction }) => {
    const getMainMessage = () => {
        if (reasons.webcamAccessDenied) {
            return "Please allow access to your webcam.";
        }

        return currentEvent.micOnly ? "Please allow access to your microphone." : "Please allow access to your webcam and mic.";
    };

    return (
        <p>
            {(reasons.mediaAccessDenied && !showSupport) && (
                <StyledMessage>
                    {getMainMessage()}
                    <ShowTipsBtn className="support_link"
                                 data-trackable={
                                     getDataOptions({
                                         view: "AccessGrantedErrorBlock",
                                         type: "button",
                                         label: "Show me how to fix this."
                                     })
                                 }
                                 onClick={() => {
                                     getSupportFaqsAction(1);
                                 }}>
                        &nbsp;Show me how to fix this.
                    </ShowTipsBtn>
                </StyledMessage>
            )}

            {reasons.webcamBlocked && (
                <StyledMessage>
                    Your webcam settings are tied to another application. <br/>
                    Please close other browsers or webcam applications (Skype, Zoom, etc.) that are using your webcam.
                </StyledMessage>
            )}

            <Button
                onClick={() => {
                    window.location.reload();
                }}
                data-trackable={
                    getDataOptions({
                        view: "AccessGrantedErrorBlock",
                        type: "button",
                        label: "Reload"
                    })
                }
            >Reload</Button>
        </p>
    );
};

export default connect(
    store => ({
        currentEvent: store.controller.currentEvent,
        showSupport: store.test.showSupport,
        reasons: store.controller.accessGrantedErrorBlockReasons,
    }),
    {
        getSupportFaqsAction
    }
)(AccessGrantedErrorBlock);
