import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { joinIsClickedAction, setCallRecordedAction } from "../store/room/actions";
import { setAppModeAction, setDirectModeAction } from "../store/controller/actions";
import JoinBlock from "../components/JoinBlock";
import AccessGrantedErrorBlock from "../components/InfoBlocs/AccessGrantedErrorBlock";
import InfoBlock from "../components/InfoBlocs/InfoBlock";
import ErrorInfoBlock from "../components/InfoBlocs/ErrorInfoBlock";
import MaxMemberInfoBlock from "../components/InfoBlocs/MaxMemberInfoBlock";
import { savePageView } from "../store/users/actions";

export const Container = styled.div`
  position: absolute;
  bottom: 12%;
  width: 100%;
  padding: 0 20px;
  color: #fff;
  text-align: center;
  text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 1001;
`;

class OfferToJoin extends React.Component {

    async componentDidMount() {
        console.log('OFFER TO JOIN');
        const liveEventPath = `live-event/${this.props.currentEvent.eventId}/`;
        const path = this.props.location.pathname.replace('room/', '').replace(liveEventPath, '');
        const roomNumber = this.props.currentSession.roomNumber !== '' ? `/${this.props.currentSession.roomNumber}` : path;

        this.props.setCallRecordedAction(false);
        setTimeout(()=>this.props.savePageView(`Room ${roomNumber}`), 1500);
    }

  render() {
      return (
          <Container>
              {this.props.errorInfoBlock && ( <ErrorInfoBlock reasons={this.props.errorInfoBlockReasons} /> )}
              {this.props.accessGrantedErrorBlock && ( <AccessGrantedErrorBlock  /> )}
              {this.props.joinIsClicked && this.props.connected && !this.props.disconnected ?
                  <>
                    {this.props.infoBlock && ( <InfoBlock reasons={this.props.infoBlockReasons} /> )}

                    {this.props.maxMemberBlock && ( <MaxMemberInfoBlock /> )}
                  </>
                  :
                  <JoinBlock />
                }
          </Container>
      )
  }
}

export default withRouter(connect(
    (store) => ({
        directMode: store.controller.directMode,
        screenView: store.controller.screenView,
        isReady: store.controller.isReady,
        accessGrantedErrorBlock: store.controller.accessGrantedErrorBlock,
        disconnected: store.controller.disconnected,
        disconnectedReasons: store.controller.disconnectedReasons,
        infoBlock: store.controller.infoBlock,
        infoBlockReasons: store.controller.infoBlockReasons,
        errorInfoBlock: store.controller.errorInfoBlock,
        errorInfoBlockReasons: store.controller.errorInfoBlockReasons,
        maxMemberBlock: store.controller.maxMemberBlock,

        recorded: store.room.needRecord,
        joinIsClicked: store.room.joinIsClicked,
        isVisitorAlone: store.room.isVisitorAlone,
        connected: store.room.connected,

        currentSession: store.controller.currentSession,
        currentEvent: store.controller.currentEvent,
        currentUser: store.users.currentUser
    }),
    {
        setCallRecordedAction,
        setDirectModeAction,
        joinIsClickedAction,
        setAppModeAction,
        savePageView
    }
)(OfferToJoin));
