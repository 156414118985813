import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import useMediaStreamTrack from "../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import { isMobileScreenSize } from "utils/helpers";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

const Video = styled.video`
  background: black;
`;

export default function VideoTrack({ isMainParticipant, track, isLocal, priority, checkEquipment = false }) {
    const ref = useRef(null);
    const { horizontablePosition, videoTracksParams, setVideoTracksParams } = useVideoContext();
    const mediaStreamTrack = useMediaStreamTrack(track);
    const isMobile = isMobileScreenSize();
    const { isPortrait } = videoTracksParams[track.name] || { isPortrait: false };

    useEffect(() => {
        if (track) {
            const trackHandler = ({dimensions}) => {
                const value = dimensions && ((dimensions.height ? dimensions.height : 0) > (dimensions.width ? dimensions.width : 0));
                setVideoTracksParams({...videoTracksParams, [track.name]: {isPortrait: value}})
            };
            track.on('started', trackHandler);
            track.on('dimensionsChanged', trackHandler);
            return () => {
                track.off('started', trackHandler);
                track.off('dimensionsChanged', trackHandler);
            }
        }

    }, [track]);

    useEffect(() => {
        const el = ref.current;
        el.muted = true;
        if (track.setPriority && priority) {
            track.setPriority(priority);
        }
        track.attach(el);

        return () => {
            track.detach(el);
            // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
            // See: https://github.com/twilio/twilio-video.js/issues/1528
            el.srcObject = null;

            if (track.setPriority && priority) {
                // Passing `null` to setPriority will set the track's priority to that which it was published with.
                track.setPriority(null);
            }
        };
    }, [track, priority, checkEquipment]);

    // const mobileParticipant = track.kind === 'video' && track.dimensions.width < track.dimensions.height;
    const style = {
        objectFit: isPortrait || track.name.includes('screen') ? 'contain' : 'cover',
        width: isMainParticipant ? '100vw' : '300px',
        height: isMainParticipant ? '100vh' : '200px',
    };

    if (isMobile) {
        style.width = horizontablePosition ? '100%' : '100vw';
        style.height = '100%';
    }

    return <Video ref={ref} style={style} />;
}
