import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Video from "twilio-video";
import styled from "styled-components";

import useRoomState from "./hooks/useRoomState/useRoomState";
import useVideoContext from "./hooks/useVideoContext/useVideoContext";
import { getToken, sendTwilioStatistics } from "../../store/twilio/actions";
import {
    setContainerLoadedAction,
    setDisconnectedAction,
    setErrorInfoBlockVisibleAction
} from "../../store/controller/actions";
import {
    endCallAction,
    joinIsClickedAction,
    sessionConnected,
    setPublisherIsReadyAction,
    toggleFooterControlsVisibilityAction
} from "../../store/room/actions";
import useChatContext from "./hooks/useChatContext/useChatContext";
import socketModule from "../../utils/socketModule";
import MenuBar from "./components/MenuBar/MenuBar";

import RecordingNotifications from "./components/RecordingNotifications/RecordingNotifications";
import Room from "./components/Room/Room";
import { identifyLogRocket } from "../../utils/helpers";

const { Logger } = Video;
const logger = Logger.getLogger("twilio-video");
const originalFactory = logger.methodFactory;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const Main = styled.main`
  overflow: hidden;
  background: black;
`;

const TwilioVideoApp = ({
                            getToken, isFetching,
                            setContainerLoadedAction, setPublisherIsReadyAction,
                            needReInitRoom, eventId, userId, roomNumber, micOnly,
                            setDisconnectedAction, accessGrantedErrorBlock,
                            toggleFooterControlsVisibilityAction,
                            sessionConnected, joinIsClickedAction, roomType,
                            twilioRoomName, currentUser, endCallAction, currentEvent,
                            sendTwilioStatistics, setErrorInfoBlockVisibleAction
                        }) => {

    const urlRoomName = window.location.href
        .replace(/#|!/g, "")
        .match(/[^\/]+$/)[0];

    const roomName = urlRoomName && !urlRoomName.includes("-x-") ? urlRoomName : null;

    const [needGetToken, setNeedGetToken] = useState(true);
    const [loadedAudioAndVideoTracks, setLoadedAudioAndVideoTracks] = useState(false);
    const [identifiedLogRocket, setIdentifiedLogRocket] = useState(false);

    const { getAudioAndVideoTracks, getLocalAudioTrack } = useVideoContext();
    const { connect: chatConnect } = useChatContext();
    const {
        connect: videoConnect,
        room,
        localTracks,
        removeLocalAudioTrack,
        removeLocalVideoTrack
    } = useVideoContext();
    const roomState = useRoomState();
    const isDisconnected = roomState === "disconnected";

    const checkClickedEndCall = (key) => {
        if (!window.sessionStorage.getItem(key)) return false;
        return window.sessionStorage.getItem(key) === roomNumber || window.sessionStorage.getItem(key) === roomName;
    }

    const hasClickedEndCall = checkClickedEndCall(`clicked-end-call`);
    const hasPartnerClickedEndCall = checkClickedEndCall(`partner-clicked-end-call`);

    // Here we would like the height of the main container to be the height of the viewport.
    // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
    // not the viewport. This looks bad when the mobile browsers location bar is open.
    // We will dynamically set the height with 'window.innerHeight', which means that this
    // will look good on mobile browsers even after the location bar opens or closes.
    // const height = useHeight();

    useEffect(() => {
        if (!identifiedLogRocket && currentEvent && currentEvent.eventId && currentUser && currentUser.userId) {
            setIdentifiedLogRocket(true);
            identifyLogRocket(currentUser, currentEvent.eventId);
        }
    }, [currentUser, currentEvent, identifiedLogRocket]);

    useEffect(() => {
        if (hasClickedEndCall || hasPartnerClickedEndCall) {
            endCallAction(false);
        }
    }, [hasClickedEndCall, hasPartnerClickedEndCall]);

    useEffect(() => {
        if (!identifiedLogRocket && eventId && currentUser && currentUser.userId) {
            setIdentifiedLogRocket(true);
            identifyLogRocket(currentUser, eventId);
        }
    }, [currentUser, eventId, identifiedLogRocket]);

    useEffect(() => {
        const videoTrack = localTracks && localTracks.length ? localTracks.find(track => track.kind === "video") : null;
        const audioTrack = localTracks && localTracks.length ? localTracks.find(track => track.kind === "audio") : null;
        const enableAccess = !hasPartnerClickedEndCall && !hasClickedEndCall && roomName && roomName !== "0" && needGetToken && !isFetching && (
            (micOnly && audioTrack) || (videoTrack && audioTrack)
        );

        (async () => {
            if (enableAccess) {
                if (isDisconnected && !room && !roomType) {
                    console.log("useEffect-80", { isDisconnected, room, needGetToken, enableAccess, roomType });
                    await handleGetToken({ videoTrack, audioTrack, room });
                } else if (needReInitRoom || (room && room.name !== roomName) || twilioRoomName !== roomName) {
                    console.log("useEffect-83", { isDisconnected, room, needGetToken, enableAccess, roomType, roomName, twilioRoomName, needReInitRoom });
                    await handleGetToken({ videoTrack, audioTrack, room });
                }
            }
        })();
    }, [localTracks, isFetching, isDisconnected, needReInitRoom, room, roomName, needGetToken, roomType, twilioRoomName, hasClickedEndCall, hasPartnerClickedEndCall]);

    useEffect(() => {
        if (!accessGrantedErrorBlock && !loadedAudioAndVideoTracks && roomName !== "0" && (isDisconnected || twilioRoomName !== roomName) && (!localTracks || !localTracks.length)) {
            setLoadedAudioAndVideoTracks(true);
            getAudioAndVideoTracks().catch(error => {
                console.log({ errorMessage: error.message, errorName: error.name });
                console.log("Error acquiring local media:");
                console.dir(error);

                //error.message === "MicrophonePermissionsDenied" -> mic
                //error.message === "CameraPermissionsDenied" -> web
                //error.name === "NotAllowedError" and error.message === '' -> web and mic

                // if (error.message === 'Permission denied by system') {
                //     getLocalAudioTrack();
                // }

                getLocalAudioTrack();
            });
            setContainerLoadedAction(); // To show the error ...
        }

        if (roomName === "0" && localTracks && localTracks.length) {
            console.log("TwilioVideoApp->roomName === \"0\"");
            setTimeout(() => {
                console.log("stop localTracks:", localTracks);
                removeLocalAudioTrack();
                removeLocalVideoTrack();
            }, 5000);
        }
    }, [getAudioAndVideoTracks, isDisconnected, accessGrantedErrorBlock, roomName, localTracks, removeLocalAudioTrack, removeLocalVideoTrack, loadedAudioAndVideoTracks, twilioRoomName]);

    useEffect(() => {
        if (loadedAudioAndVideoTracks) {
            setTimeout(() => setLoadedAudioAndVideoTracks(false), 5000);
        }
    }, [loadedAudioAndVideoTracks]);

    const handleGetToken = async ({ videoTrack, audioTrack, room }) => {
        console.log("------------------------");
        console.log("handleGetToken", roomName);
        console.log("------------------------");
        setNeedGetToken(false);

        if (room) await room.disconnect();
        if (videoTrack && videoTrack.isStopped) await videoTrack.restart();
        if (audioTrack && audioTrack.isStopped) await audioTrack.restart();

        //roomController init()
        socketModule.socket.emit("data_uid", userId);

        const { token, room_sid} = await getToken(roomName);

        if (!token) return console.error("handleGetToken: Token is missing!");

        // Set Logger:
        console.log("[Start Twilio Video Logger]", { roomName, token });
        logger.methodFactory = function(methodName, level, loggerName) {
            const method = originalFactory(methodName, level, loggerName);
            return function(datetime, logLevel, component, message, data) {
                const prefix = "[Twilio Video Logger]";
                method(prefix, logLevel, component, message, data);
                // check for signaling events that previously used to be
                // emitted on (now deprecated) eventListener
                // they are fired with message = `event`, and group == `signaling`
                if (message === "event" && data.group === "signaling") {
                    if (data.name === "waiting") {
                        const message = "Twilio's signaling server is busy, so we wait a little while before trying again.";
                        console.warn(message);
                        sendTwilioStatistics({ message, method: 'Twilio Video Logger', data }, "FE Logger");
                    } else if (data.name === "connecting") {
                        console.log("Connecting to Twilio's signaling server.");
                    } else if (data.name === "open") {
                        console.log("Connected to Twilio's signaling server, joining the Room now.");
                    } else if (data.name === "closed") {
                        if (data.level === "error") {
                            const message = "Connection to Twilio's signaling server abruptly closed";
                            const { payload: { reason } } = data;
                            console.error(message, data.reason || reason);
                            sendTwilioStatistics({ message, method: 'Twilio Video Logger', data }, "FE Logger");
                            setTimeout(()=> {
                                const hasRefreshed = JSON.parse(
                                    window.sessionStorage.getItem(`twilio-${roomName}-refreshed`) || 'false'
                                );
                                // if not been refreshed yet
                                if (!hasRefreshed) {
                                    window.sessionStorage.setItem(`twilio-${roomName}-refreshed`, 'true'); // we are now going to refresh
                                    return window.location.reload(); // refresh the page
                                } else {
                                    setErrorInfoBlockVisibleAction(true, {networkError: true});
                                }
                            }, 1000);
                        } else {
                            console.log("Connection to Twilio's signaling server closed.");
                        }
                    }
                }
            };
        };
        logger.setLevel("error");

        // For NextPartnerBio
        window.sessionStorage.setItem(`room-loaded-${eventId}`, roomNumber);

        // won't work without getAudioAndVideoTracks()
        await videoConnect(token); // must be localVideoTrack
        await chatConnect(token, room_sid); // must be localAudioTrack

        //roomController this.publisher = this.openTok.initPublisher()
        setContainerLoadedAction(); // ...disableGreenScreenAction()

        socketModule.socket.emit("fishbowl.invited_user_moving_to_room", {
            eventId, roomNumber
        });

        //roomController publishPublisher
        socketModule.socket.emit("bio_progress.complete_joining", { eventId, userId, roomNumber, joined: true });
        joinIsClickedAction();
        sessionConnected();
        toggleFooterControlsVisibilityAction(true);
        setDisconnectedAction(false, {});

        setTimeout(() => {
            setPublisherIsReadyAction(true);
        }, 1000);

        setTimeout(() => {
            setNeedGetToken(true);
        }, 5000);

    };

    return (
        <Container style={{ height: "100vh" }}>
            {isDisconnected ? (
                <div/>
            ) : (
                <Main>
                    <RecordingNotifications/>
                    <Room/>
                    <MenuBar/>
                </Main>
            )}
            <div style={{position: 'absolute', bottom: 0, left: 0, backgroundColor: 'white', padding: 5}}>
                Room type: {roomType}
            </div>
        </Container>
    );
};

const mapStateToProps = (store) => ({
    isFetching: store.twilio.isFetching,
    roomType: store.twilio.roomType,
    needReInitRoom: store.twilio.needReInitRoom,
    eventId: store.controller.currentEvent.eventId,
    currentEvent: store.controller.currentEvent,
    userId: store.users.currentUser.userId,
    currentUser: store.users.currentUser,
    micOnly: store.controller.currentEvent.micOnly || store.users.micOnlyMode,
    accessGrantedErrorBlock: store.controller.accessGrantedErrorBlock,
    endCallClicked: store.room.endCallClicked,
    twilioRoomName: store.twilio.roomName,
    roomNumber: store.controller.currentSession ? store.controller.currentSession.roomNumber : null
});

const mapDispatchToProps = {
    getToken,
    setContainerLoadedAction,
    setPublisherIsReadyAction,
    setDisconnectedAction,
    toggleFooterControlsVisibilityAction,
    sessionConnected,
    joinIsClickedAction,
    endCallAction,
    sendTwilioStatistics,
    setErrorInfoBlockVisibleAction
};

export default connect(mapStateToProps, mapDispatchToProps)(TwilioVideoApp);
