import React from "react";
import styled from "styled-components";

import { colors } from "./variables";

const Preloader = styled.div`
  background-color: ${pr => pr.backgroundColor ? pr.backgroundColor : colors.grey};
  opacity: ${pr => pr.opacity ? pr.opacity : 1};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;

  & > div {
    width: fit-content;
  }
`;

const LoaderBlock = styled.div`
  margin: 10px auto;
  border: 3px solid #d9d9d9;
  border-top: 3px solid #919191;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const LoadingBlock = styled.div`
  position: relative;

  //&:after {
  //  position: absolute;
  //  left: 100%;
  //  content: "...";
  //  animation: loading 1200ms linear infinite;
  //}
  //
  //@-webkit-keyframes loading {
  //  0% { content: ""; }
  //  25% { content: "."; }
  //  50% { content: ".."; }
  //  75% { content: "..."; }
  //}
  //@keyframes loading {
  //  0% { content: ""; }
  //  25% { content: "."; }
  //  50% { content: ".."; }
  //  75% { content: "..."; }
  //}
`;


const Loader = ({backgroundColor, opacity, style, showLoader = true, showLoading = false}) => {
    const isHomePage = document.location.pathname === '/' || document.location.pathname === '/home';
    const isOrganizerFlow = document.location.pathname.includes('/m/');
    const isPreviewFlow = document.location.pathname.includes('/preview/');

    const showLogo = isHomePage || isOrganizerFlow || isPreviewFlow;

    return (
        <Preloader {...{backgroundColor, opacity, style}}>
            <div style={{paddingTop: showLogo ? 0 : 44}}>
                {showLogo && <div>Meetaway</div>}
                {showLoader && <LoaderBlock  />}
                {showLoading && <LoadingBlock>Loading</LoadingBlock>}
            </div>
        </Preloader>
    );
}

export default Loader;
