import React, { useEffect, useRef, useState } from "react";
import Styles from "./styles";
import socketModule from "utils/socketModule";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useChatContext from "../../../hooks/useChatContext/useChatContext";

const ALLOWED_FILE_TYPES =
    "audio/*, image/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document .xslx, .ppt, .pdf, .key, .svg, .csv";

const ChatInput = ({
                       currentEvent, currentSession, currentUser, isWebinar, isPreview = false, addMessage = () => {
    }
                   }) => {
    const { isChatWindowOpen, chatUser, sendFile, sendMessage, isSendingFile } = useChatContext();

    const [messageBody, setMessageBody] = useState("");
    const [fileSendError, setFileSendError] = useState(null);
    const textInputRef = useRef(null);
    const fileInputRef = useRef(null);
    const isValidMessage = /\S/.test(messageBody);

    useEffect(() => {
        if (isChatWindowOpen) {
            textInputRef && textInputRef.current && textInputRef.current.focus();
        }
    }, [isChatWindowOpen]);

    const handleChange = (event) => {
        setMessageBody(event.target.value);
    };

    // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
    const handleReturnKeyPress = e => {
        if (e.key === "Enter") {
            e.preventDefault();
            // just add line break; so complicated because ctrlKey by default doesn't add line break
            if (e.shiftKey || e.ctrlKey) {
                setMessageBody(`${messageBody}\n`);
            } else {
                handleSendMessage(messageBody);
            }
        }
    };

    const handleSendFile = (event) => {
        const file = event.target && event.target.files ? event.target.files[0] : null;
        if (file) {
            sendFile(file);
        }
    };

    const handleSendMessage = () => {
        if (isValidMessage) {
            sendMessage(messageBody.trim());

            const urlRoomName = window.location.href
                .replace(/#|!/g, "")
                .match(/[^\/]+$/)[0];

            socketModule.socket.emit("chat.new_message", {
                eventId: currentEvent.eventId,
                roomNumber: currentSession.roomNumber || urlRoomName,
                userId: currentUser.userId,
                message: messageBody.trim(),
            });

            setMessageBody("");
        }
    };

    return (
        <Styles.InputWrapper isWebinar={isWebinar} isPreview={isPreview}>
            {Boolean(fileSendError) && <Styles.ErrorPopup>
                <FontAwesomeIcon icon={faTimes} onClick={() => setFileSendError(null)}/>
                {fileSendError || ""}</Styles.ErrorPopup>}
            <Styles.Textarea
                isWebinar={isWebinar}
                ref={textInputRef}
                onChange={handleChange}
                onKeyPress={handleReturnKeyPress}
                value={messageBody}
                placeholder={"Type a message..."}
                isPreview={isPreview}
            />

            {/*<input*/}
            {/*    ref={fileInputRef}*/}
            {/*    type="file"*/}
            {/*    style={{ display: "none" }}*/}
            {/*    onChange={handleSendFile}*/}
            {/*    value={""}*/}
            {/*    accept={ALLOWED_FILE_TYPES}*/}
            {/*    disabled={isPreview}*/}
            {/*/>*/}
            {/*{isWebinar*/}
            {/*    ? <div style={{ position: "absolute", right: 0, display: "flex" }}>*/}
            {/*        {!isPreview && <Styles.Button*/}
            {/*            fileButton={isWebinar}*/}
            {/*            onClick={() => fileInputRef && fileInputRef.current && fileInputRef.current.click()}*/}
            {/*            disabled={isSendingFile}*/}
            {/*            isWebinar={isWebinar}*/}
            {/*        >*/}
            {/*            {*/}
            {/*                isSendingFile*/}
            {/*                    ? <Styles.Spiner/>*/}
            {/*                    : <FontAwesomeIcon icon={faPaperclip}/>*/}
            {/*            }*/}
            {/*        </Styles.Button>}*/}

            {/*        {isWebinar && <Styles.Button onClick={handleSendMessage} isWebinar={isWebinar}>*/}
            {/*            <Styles.Img src={PaperPlaneRight} alt="send"/>*/}
            {/*        </Styles.Button>}*/}
            {/*    </div>*/}
            {/*    : <Styles.Button*/}
            {/*        onClick={() => fileInputRef && fileInputRef.current && fileInputRef.current.click()}*/}
            {/*        disabled={isSendingFile}*/}
            {/*    >*/}
            {/*        {*/}
            {/*            isSendingFile*/}
            {/*                ? <Styles.Spiner/>*/}
            {/*                : <FontAwesomeIcon icon={faPaperclip}/>*/}
            {/*        }*/}
            {/*    </Styles.Button>*/}
            {/*}*/}

        </Styles.InputWrapper>
    );
};

export default connect(
    store => {
        return {
            currentEvent: store.controller.currentEvent,
            currentSession: store.controller.currentSession,
            currentUser: store.users.currentUser
        };
    }
)(ChatInput);
