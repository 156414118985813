import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { VideoCapturingState } from "@zoom/videosdk";
import { setDeviceChangingAction } from "store/controller/actions";

import AudioController from "./AudioController";
import VideoController from "./VideoController";
import ChatController from "./ChatController";
import CallMobileController from "./CallMobileController";
import { Container } from "./styled";
import Menu from "../MenuBar/Menu";
import { isMobile } from "../../utils/util";

import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useAudioVideoStatistic from "../../hooks/useAudioVideoStatistic/useAudioVideoStatistic";

const FooterControlsContainer = styled(Container)`
  align-items: center;
`;

const FooterControls = ({ isScreenSharing, zmClient }) => {
    const { mediaContext: {mediaStream} } = useVideoContext();

    const [isStartedAudio, setIsStartedAudio] = useState(zmClient.getCurrentUserInfo() && zmClient.getCurrentUserInfo().audio !== '');
    const [isStartedVideo, setIsStartedVideo] = useState(zmClient.getCurrentUserInfo()?.bVideoOn);
    const [initializedVideo, setInitializedVideo] = useState(zmClient.getCurrentUserInfo()?.bVideoOn);
    const [initializedAudio, setInitializedAudio] = useState(zmClient.getCurrentUserInfo() && zmClient.getCurrentUserInfo().audio !== '');
    const [isMuted, setIsMuted] = useState(!!zmClient.getCurrentUserInfo()?.muted);

    useAudioVideoStatistic({ isStartedAudio, isStartedVideo, isMuted });

    const onVideoCaptureChange = useCallback((payload) => {
        if (payload.state === VideoCapturingState.Started) {
            setIsStartedVideo(true);
            setTimeout(()=>{
                setInitializedVideo(true);
            }, 3000);
        } else {
            setIsStartedVideo(false);
        }
    }, []);

    useEffect(() => {
        zmClient.on('video-capturing-change', onVideoCaptureChange);

        setTimeout(()=>{
            setInitializedAudio(true);
        }, 4000);

        return () => {
            zmClient.off('video-capturing-change', onVideoCaptureChange);
        };
    }, [zmClient, onVideoCaptureChange]);

    useEffect(() => {
        if (mediaStream && zmClient.getSessionInfo().isInMeeting) {
            mediaStream.subscribeAudioStatisticData();
            mediaStream.subscribeVideoStatisticData();
            mediaStream.subscribeShareStatisticData();
        }

        return () => {
            if (mediaStream && zmClient.getSessionInfo().isInMeeting) {
                mediaStream.unsubscribeAudioStatisticData();
                mediaStream.unsubscribeVideoStatisticData();
                mediaStream.unsubscribeShareStatisticData();
            }
        };
    }, [mediaStream, zmClient]);

    return (
        <>
            <FooterControlsContainer>
                <VideoController
                    {...{
                        isStartedVideo, setIsStartedVideo, initializedVideo
                    }}
                />
                <AudioController
                    {...{
                        isStartedAudio, setIsStartedAudio,
                        isMuted, setIsMuted, initializedAudio
                    }}
                />
                <ChatController/>
                <Menu {...{ isScreenSharing }} />
                {isMobile && <CallMobileController/> }
            </FooterControlsContainer>
        </>
    );
};

export default connect(
    (state) => ({
        zmClient: state.zoom.zmClient,
    }),
    {
        setDeviceChangingAction
    }
)(FooterControls);
