import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { ChatFileDownloadStatus } from "@zoom/videosdk";
import produce from "immer";
import { v4 as uuidv4 } from "uuid";

import { useParticipantsChange } from "../../hooks/useParticipantsChange/useParticipantsChange";
import { isImageFile } from "../../utils/chatUtils";
import store from "../../../../store";
import { getChatMessages } from "../../../../store/zoom/actions";

export const ChatContext = createContext(null);

export const ChatProvider = ({ children }) => {
    const {zoom: {zmClient}} = store.getState();
    const chatClient = zmClient.getChatClient();

    const [isSendingFile, setIsSendingFile] = useState(false);
    const isChatWindowOpenRef = useRef(false);
    const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
    const [records, setRecords] = useState([]);
    const [receivers, setReceivers] = useState([]);
    const [chatUser, setChatUser] = useState(null);

    const onChatMessage = useCallback(
        (payload) => {
            setRecords(
                produce((records) => {
                    const { length } = records;
                    if (length > 0) {
                        const currentUserId = zmClient.getSessionInfo().userId;
                        if (payload.file && payload.sender.userId === currentUserId) {
                            const record = records.find(
                                (item) =>
                                    item.sender.userId === currentUserId &&
                                    item.file?.name === payload.file?.name &&
                                    item.file?.size === payload.file?.size &&
                                    item.file?.type === payload.file?.type &&
                                    item.receiver.userId === payload.receiver.userId &&
                                    !item.id
                            );
                            if (record) {
                                const objectUrl = record.file?.originalFileObjectUrl;
                                let nPayload = payload;
                                if (objectUrl && payload.file) {
                                    const { file, ...restProps } = payload;
                                    nPayload = {
                                        ...restProps,
                                        file: {
                                            ...file,
                                            originalFileObjectUrl: objectUrl
                                        }
                                    };
                                }
                                Object.assign(record, { ...nPayload });
                            }
                        } else {
                            records.push(payload);
                        }
                    } else {
                        records.push(payload);
                    }
                })
            );
            setHasUnreadMessages(true);
            setIsChatWindowOpen(true);
        },
        [zmClient]
    );

    const onFileUploadProgressChange = useCallback(
        (payload) => {
            const { fileName, fileSize, receiverId, progress, status, retryToken } = payload;
            const currentUserId = zmClient.getSessionInfo().userId;
            setRecords(
                produce((records) => {
                    const record = records
                        .slice(0)
                        .reverse()
                        .find(
                            (item) =>
                                !item.id &&
                                item.file?.name === fileName &&
                                item.file?.size === fileSize &&
                                item.sender.userId === currentUserId &&
                                item.receiver.userId === receiverId
                        );
                    if (record?.file?.upload) {
                        Object.assign(record.file.upload, { progress, status, retryToken });
                    }
                })
            );
        },
        [zmClient]
    );

    const onFileDownloadProgressChange = useCallback((payload) => {
        const { id, fileName, fileSize, fileUrl, fileBlob, senderId, progress, status } = payload;
        setRecords(
            produce((records) => {
                const record = records.find(
                    (item) =>
                        item.id === id &&
                        item.file?.name === fileName &&
                        item.file?.size === fileSize &&
                        item.file?.fileUrl === fileUrl &&
                        item.sender.userId === senderId
                );
                if (record?.file?.download) {
                    Object.assign(record.file.download, { progress, status });
                }
                if (record?.file && fileBlob) {
                    Object.assign(record?.file, { originalFileObjectUrl: window.URL.createObjectURL(fileBlob) });
                }
            })
        );
    }, []);

    const sendMessage = useCallback(
        (text) => {
            const { currentUser } = store.getState().users;
            const data = JSON.stringify({
                text,
                attributes: {
                    photo: currentUser.profilePhoto,
                    initials: `${currentUser.firstName.substring(0, 1)}${currentUser.lastName.substring(0, 1)}`.toUpperCase(),
                    color: currentUser.profileColorHex,
                    userId: currentUser.userId
                }
            });

            chatClient.sendToAll(data);
        },
        [chatClient]
    );

    const sendFile = useCallback(
        async (file) => {
            setIsSendingFile(true);
            if (chatUser) {
                const cancelFunc = await chatClient.sendFile(file, chatUser.userId);
                setRecords(
                    produce((records) => {
                        records.push({
                            file: {
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                uuid: uuidv4(),
                                originalFileObjectUrl: file && isImageFile(file.name) ? window.URL.createObjectURL(file) : undefined,
                                upload: {
                                    cancelFunc,
                                    status: 0,
                                    progress: 0
                                }
                            },
                            sender: {
                                name: "",
                                userId: zmClient.getSessionInfo().userId
                            },
                            receiver: {
                                name: chatUser.displayName,
                                userId: chatUser.userId
                            },
                            timestamp: new Date().getTime()
                        });
                    })
                );
            }
            setIsSendingFile(false);
        },
        [chatUser, chatClient, zmClient]
    );

    const resendFile = useCallback(
        async (retryToken, fileUuid) => {
            if (chatUser) {
                const cancelFunc = await chatClient.sendFile(retryToken, chatUser.userId);
                setRecords(
                    produce((records) => {
                        const record = records.find((item) => item.file?.uuid === fileUuid);
                        if (record?.file?.upload) {
                            Object.assign(record.file?.upload, { cancelFunc });
                        }
                    })
                );
            }
        },
        [chatUser, chatClient]
    );

    const downloadFile = useCallback(
        async (id, blob) => {
            const record = records.find((item) => item.id === id);
            if (record?.file?.fileUrl) {
                const cancelFunc = await chatClient.downloadFile(id, record.file.fileUrl, blob);
                setRecords(
                    produce((records) => {
                        const draftRecord = records.find((item) => item.id === id);
                        if (draftRecord?.file) {
                            Object.assign(draftRecord.file, {
                                download: { cancelFunc, status: ChatFileDownloadStatus.InProgress, progress: 0 }
                            });
                        }
                    })
                );
            }
        },
        [chatClient, records]
    );

    const setChatUserId = useCallback(
        (userId) => {
            const user = receivers.find((user) => user.userId === userId);
            if (user) {
                setChatUser(user);
            }
        },
        [receivers]
    );

    useParticipantsChange(zmClient, () => {
        if (chatClient) {
            setReceivers(chatClient.getReceivers());
        }
    });

    useEffect(() => {
        zmClient.on("chat-on-message", onChatMessage);
        zmClient.on("chat-file-upload-progress", onFileUploadProgressChange);
        zmClient.on("chat-file-download-progress", onFileDownloadProgressChange);

        return () => {
            zmClient.off("chat-on-message", onChatMessage);
            zmClient.off("chat-file-upload-progress", onFileUploadProgressChange);
            zmClient.off("chat-file-download-progress", onFileDownloadProgressChange);
        };
    }, [zmClient, onChatMessage, onFileUploadProgressChange, onFileDownloadProgressChange]);


    useEffect(() => {
        if (chatUser) {
            const index = receivers.findIndex((user) => user.userId === chatUser.userId);
            if (index === -1) {
                setChatUser(receivers[0]);
            }
        } else {
            if (receivers.length > 0) {
                setChatUser(receivers[0]);
            }
        }
    }, [receivers, chatUser]);

    useEffect(() => {
        isChatWindowOpenRef.current = isChatWindowOpen;
        if (isChatWindowOpen) setHasUnreadMessages(false);
    }, [isChatWindowOpen]);

    useEffect(() => {
        (async () => {
            const isExistEvent = Boolean(store.getState()?.controller?.currentEvent?.eventId);
            const messages = await store.dispatch(getChatMessages(isExistEvent));

            setRecords(messages);
        })();
    }, []);


    return (
        <ChatContext.Provider
            value={{
                isChatWindowOpen,
                setIsChatWindowOpen,
                hasUnreadMessages,
                setHasUnreadMessages,
                records,
                receivers,
                chatUser,
                sendMessage,
                sendFile,
                resendFile,
                downloadFile,
                setChatUserId,
                isSendingFile
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};


