import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import useVideoContext from "./hooks/useVideoContext/useVideoContext";
import {
    endCallAction,
    joinIsClickedAction,
    sessionConnected,
    setPublisherIsReadyAction,
    toggleFooterControlsVisibilityAction
} from "../../store/room/actions";
import { setContainerLoadedAction, setDisconnectedAction } from "../../store/controller/actions";
import { getToken } from "../../store/zoom/actions";
import socketModule from "../../utils/socketModule";
import Room from "./component/Room/Room";
import { SelectedParticipantProvider } from "./component/VideoProvider/useSelectedParticipant/useSelectedParticipant";
import { ChatProvider } from "./component/ChatProvider";
import Loader from "../Loader";


const Container = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100vh;
`;
const Main = styled.main`
    overflow: hidden;
    background: black;
`;

const ZoomApp = (props) => {
    const {
        urlRoomName, getToken, isFetching, installedVideoSDK, zmClient,
        setContainerLoadedAction, setPublisherIsReadyAction,
        needReInitRoom, eventId, userId, roomNumber,
        toggleFooterControlsVisibilityAction,
        sessionConnected, joinIsClickedAction, roomType,
        zoomRoomName, userName, endCallAction, setDisconnectedAction
    } = props;

    const roomName = urlRoomName && !urlRoomName.includes("-x-") ? urlRoomName : null;

    const { zoomConnect, loading, connectionStatus } = useVideoContext();

    const [needGetToken, setNeedGetToken] = useState(true);

    console.log('ZoomApp', roomName, {
        loading, connectionStatus
    });

    const checkClickedEndCall = (key) => {
        if (!window.sessionStorage.getItem(key)) return false;
        return window.sessionStorage.getItem(key) === roomNumber || window.sessionStorage.getItem(key) === roomName;
    };
    const hasClickedEndCall = checkClickedEndCall(`clicked-end-call`);
    const hasPartnerClickedEndCall = checkClickedEndCall(`partner-clicked-end-call`);

    useEffect(() => {
        setContainerLoadedAction();
    }, []);

    useEffect(() => {
        if (hasClickedEndCall || hasPartnerClickedEndCall) {
            endCallAction(false);
        }
    }, [hasClickedEndCall, hasPartnerClickedEndCall]);

    useEffect(() => {
        const enableAccess =
            installedVideoSDK &&
            !hasPartnerClickedEndCall &&
            !hasClickedEndCall &&
            roomName &&
            needGetToken &&
            !isFetching;

        (async () => {
            if (enableAccess) {
                if (!roomType) {
                    console.log("INIT ROOM", { needGetToken, enableAccess, roomType });
                    await handleGetToken();
                } else if (needReInitRoom || zoomRoomName !== roomName) {
                    console.log("RE-INIT ROOM", {
                        needGetToken,
                        enableAccess,
                        roomType,
                        roomName,
                        zoomRoomName,
                        needReInitRoom
                    });
                    await handleGetToken();
                }
            }
        })();
    }, [
        isFetching, needReInitRoom, roomName, needGetToken, roomType,
        zoomRoomName, hasClickedEndCall, hasPartnerClickedEndCall,
        installedVideoSDK
    ]);

    const handleGetToken = async () => {
        console.log("------------------------");
        console.log("handleGetToken", roomName);
        console.log("------------------------");
        setNeedGetToken(false);

        await zmClient.leave();

        socketModule.socket.emit("data_uid", userId);

        const { token } = await getToken(roomName);
        if (!token) return console.error("handleGetToken: Token is missing!");

        // For NextPartnerBio
        window.sessionStorage.setItem(`room-loaded-${eventId}`, roomNumber);

        await zoomConnect({ roomName, userName, token });

        setContainerLoadedAction();

        socketModule.socket.emit("fishbowl.invited_user_moving_to_room", {
            eventId, roomNumber
        });
        socketModule.socket.emit("bio_progress.complete_joining", {
            eventId, userId, roomNumber, joined: true
        });

        setDisconnectedAction(false, {});

        setTimeout(() => {
            setPublisherIsReadyAction(true);
            toggleFooterControlsVisibilityAction(true);
        }, 2000);

        setTimeout(() => {
            setNeedGetToken(true);
        }, 5000);
    };

    useEffect(() => {
        if (!loading) {
            joinIsClickedAction();
            sessionConnected();
        }
    }, [loading]);

    if (hasClickedEndCall || hasPartnerClickedEndCall) {
        return null;
    }

    if (!installedVideoSDK || loading) {
        return (
            <Loader
                showLoading={true}
                style={{
                    position: "absolute",
                    paddingBottom: 40,
                    fontSize: 20,
                    zIndex: 1,
                    color: "#ffffff"
                }}
                backgroundColor={"#000000"}
            />
        );
    } else if (connectionStatus === "closed") {
        return null;
    }

    return (
            <SelectedParticipantProvider zmClient={zmClient}>
                <ChatProvider>
                    <Container>
                        <Main>
                            <Room/>
                        </Main>
                    </Container>
                </ChatProvider>
            </SelectedParticipantProvider>
    );
};

const mapStateToProps = (store) => ({
    zmClient: store.zoom.zmClient,
    installedVideoSDK: store.zoom.installedVideoSDK,
    isFetching: store.zoom.isFetching,
    roomType: store.zoom.roomType,
    needReInitRoom: store.zoom.needReInitRoom,
    eventId: store.controller.currentEvent.eventId,
    userId: store.users.currentUser.userId,
    userName: `${store.users.currentUser.firstName} ${store.users.currentUser.lastName}`,
    zoomRoomName: store.zoom.roomName,
    roomNumber: store.controller.currentSession ? store.controller.currentSession.roomNumber : null
});

const mapDispatchToProps = {
    getToken,
    setContainerLoadedAction,
    setPublisherIsReadyAction,
    setDisconnectedAction,
    toggleFooterControlsVisibilityAction,
    sessionConnected,
    joinIsClickedAction,
    endCallAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZoomApp);
