import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { SELECTED_BACKGROUND_SETTINGS_KEY } from "../../utils/videoConstants";
import { getBackgroundSettings, getImagePath, isSupportedVB } from "../../utils/util";


const useBackgroundSettings = ({
                                   isStartedVideo, handlerUpdateVB, handlerClearVB, handlerStartVB
}) => {
    const currentEvent = useSelector((state) => state.controller.currentEvent);
    const isUseVirtualBackground = useSelector((state) => state.system.virtualBackground);
    const backgroundSettings = getBackgroundSettings();
    const currentVBPath = getImagePath(backgroundSettings);

    const [loading, setLoading] = useState(false);
    const [isInVBMode, setIsInVBMode] = useState(!!currentVBPath);
    const [currentVB , setCurrentVB] = useState(currentVBPath);

    const onChangeBackground = useCallback(async(value) => {
        const imgPath = getImagePath(value);

        if (loading || imgPath === currentVB || !isSupportedVB || !isUseVirtualBackground || !isStartedVideo ) {
            return;
        }
        setLoading(true);
        window.localStorage.setItem(SELECTED_BACKGROUND_SETTINGS_KEY, JSON.stringify(value));
        setCurrentVB(imgPath);
        await virtualBackgroundHandler(imgPath);
        setLoading(false);
    }, [
        currentEvent, isSupportedVB, isUseVirtualBackground, currentVB,
        isInVBMode, loading,  isStartedVideo,
        handlerUpdateVB, handlerClearVB, handlerStartVB
    ]);

    const virtualBackgroundHandler = async (imageUrl) => {
        try {
            if (isInVBMode) {
                if (imageUrl) {
                    await handlerUpdateVB(imageUrl);
                } else {
                    await handlerClearVB();
                    setIsInVBMode(false);
                }
            } else {
                await handlerStartVB(imageUrl);
                setIsInVBMode(true);
            }
        } catch (e) {
            console.error('virtualBackgroundHandler', e);
        }
    };

    return [backgroundSettings, onChangeBackground, isInVBMode];
};

export default useBackgroundSettings;
