import DetectRTC from "detectrtc";

import store from "store";
import { isAndroidOrIOSBrowser } from "./platform";
import Abstract from "../images/Abstract.jpg";
import BohoHome from "../images/BohoHome.jpg";
import Bookshelf from "../images/Bookshelf.jpg";
import CoffeeShop from "../images/CoffeeShop.jpg";
import Contemporary from "../images/Contemporary.jpg";
import CozyHome from "../images/CozyHome.jpg";
import Desert from "../images/Desert.jpg";
import Fishing from "../images/Fishing.jpg";
import Flower from "../images/Flower.jpg";
import Kitchen from "../images/Kitchen.jpg";
import ModernHome from "../images/ModernHome.jpg";
import Nature from "../images/Nature.jpg";
import Ocean from "../images/Ocean.jpg";
import Patio from "../images/Patio.jpg";
import Plant from "../images/Plant.jpg";
import SanFrancisco from "../images/SanFrancisco.jpg";
import { SELECTED_BACKGROUND_SETTINGS_KEY } from "./videoConstants";

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();
export const isSupportedVB = (() => {
  return !isAndroidOrIOSBrowser() && (DetectRTC.browser.isChrome || DetectRTC.browser.isEdge);
})();

export const getImagePath = (backgroundSettings) => {
  const rawImagePaths = [
    Abstract,
    BohoHome,
    Bookshelf,
    CoffeeShop,
    Contemporary,
    CozyHome,
    Desert,
    Fishing,
    Flower,
    Kitchen,
    ModernHome,
    Nature,
    Ocean,
    Patio,
    Plant,
    SanFrancisco
  ];

  const {
    controller: { currentEvent },
    system: { virtualBackground }
  } = store.getState();

  if (!isSupportedVB || !virtualBackground) {
    return null;
  }

  if (backgroundSettings.type === "event" && currentEvent.defaultVirtualBackground) {
    return currentEvent.defaultVirtualBackground;
  }
  if (backgroundSettings.type === "blur") {
    return "blur";
  }
  if (backgroundSettings.type === "none") {
    return  null;
  }
  if (typeof backgroundSettings.index === "number") {
    return rawImagePaths[backgroundSettings.index];
  }

  return null;
};

export const getBackgroundSettings = () => {
  const localStorageSettings = window.localStorage.getItem(SELECTED_BACKGROUND_SETTINGS_KEY);
  return localStorageSettings ? JSON.parse(localStorageSettings) : { type: "none", index: 0 };
};
