import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { setDirectModeAction } from "../../store/controller/actions";
import { identifyLogRocket } from "../../utils/helpers";
import { VideoProvider } from "./component/VideoProvider";
import ZoomApp from "./ZoomApp";


const ZoomVideo = ({ currentUser, setDirectModeAction, zmClient, ...props }) => {
    const roomRoute = _.get(props, "location.pathname", "").search("/zoom-room/") !== -1;
    const pathname = window.location.href
        .replace(/#|!/g, "")
        .match(/[^\/]+$/);
    const urlRoomName = pathname ? pathname[0] : null;

    const [identifiedLogRocket, setIdentifiedLogRocket] = useState(false);

    useEffect(() => {
        if (roomRoute) {
            setDirectModeAction(true);
        }
    }, [roomRoute]);

    useEffect(() => {
        if (roomRoute) {
            if (!identifiedLogRocket && currentUser.userId) {
                setIdentifiedLogRocket(true);
                identifyLogRocket(currentUser, urlRoomName);
            }
        }
    }, [currentUser, identifiedLogRocket, urlRoomName]);

    if (!urlRoomName || urlRoomName === "0") return null;

    return (
        <VideoProvider zmClient={zmClient}>
            <ZoomApp urlRoomName={urlRoomName}/>
        </VideoProvider>
    );
};

const mapStateToProps = (store) => ({
    zmClient: store.zoom.zmClient,
    currentUser: store.users.currentUser,
});

const mapDispatchToProps = {
    setDirectModeAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ZoomVideo);
