import React from "react";
import styled from "styled-components";
import DetectRTC from "detectrtc";

import GreenButton from "components/Common/GreenButton";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import store from "../../../../store";

const ScreenShareBanner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  z-index: 8;
  padding: 0 25px 19px 25px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);

  p {
    color: white;
    font-size: 16px;
    margin-right: 10px;
    padding-top: 14px;
  }
`;

const Button = styled(GreenButton)`
    margin-top: 13px;
    height: 40px;
    width: fit-content;
    font-size: 16px;
    padding: 0 12px;
    line-height: 1;
    border-radius: 3px;
`;

export default function NoChromeStopSharingButton() {
    const { mediaContext: {mediaStream} } = useVideoContext();
    const {zoom: {zmClient}} = store.getState();
    const isCurrentUserStartedScreen = zmClient.getCurrentUserInfo()?.sharerOn;

    const stopSharingHandler = () => {
        mediaStream?.stopShareScreen();
    }

    return (
        <>
            {isCurrentUserStartedScreen && !DetectRTC.browser.isChrome && (
                <ScreenShareBanner>
                    <p>You are sharing your screen</p>
                    <Button onClick={stopSharingHandler}>Stop Sharing</Button>
                </ScreenShareBanner>
            )}
        </>
    );
}
