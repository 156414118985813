import { getInstanceAxios, prepareUserData } from "../../utils/helpers";
import config from "../../utils/config";
import types from "../constans";
import { setCurrentUserAction } from "../users/actions";
import { logout, setSystemToken } from "../../utils/AuthManager";
import { setEventRegistrationsAction } from "../checkin/actions";
import { setOrganizerSidebarDataBubbleUserAction } from "../organizer/actions";

export const getAccountsAction = (type) => async (dispatch, getState) => {
    const {users: {currentUser: {defaultAccount}}} = getState();

    dispatch({
        type: types.SET_HOME_PAGE_ACCOUNTS,
        accounts: [],
    });

    const response = await getInstanceAxios().get(`${config.NODE_API_URL}/home/accounts/${type}`, {crossDomain: true});
    if (response && response.data && response.data.status === 200) {
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: response.data.accounts.sort((a, b) => {
                if (a.account === defaultAccount) return -1;
                if (b.account === defaultAccount) return 1;
                return 0;
            }),
        });

        return response.data.accounts;
    }

    return null;
};

export const setAccountAction = ({account}) => async (dispatch, getState) => {
    const {home: {accounts}} = getState();

    const existIndexAccount = accounts.findIndex(data => data.account === account.account);
    if (existIndexAccount === -1) {
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts, account],
        });
    } else {
        accounts[existIndexAccount] = account;
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts],
        });
    }
};

export const checkCardByAccountAction = async (accountId)=> {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/home/check-card`, {accountId});
    return response && response.data ? response.data : {text: ''};
};

export const getEventsByAccountAction = ({accountId, isInit}) => async (dispatch, getState) => {
    const {priorEvents} = getState().home.events;
    const priorEventsIds = isInit ? [] : priorEvents && priorEvents.length ? priorEvents.map(({event}) => event) : [];

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/home/events/${accountId}`,
        {priorEventsIds},
        {crossDomain: true});

    if (response && response.data && response.data.status === 200) {
        dispatch({
            type: types.SET_HOME_PAGE_EVENTS,
            events: {
                upcomingEvents: response.data.upcomingEvents,
                priorEvents: isInit ? response.data.priorEvents : [...priorEvents, ...response.data.priorEvents],
                templateEvents: response.data.templateEvents || [],
                recurringEvents: response.data.recurringEvents || [],
                upcomingEventsCount: response.data.upcomingEventsCount,
                priorEventsCount: response.data.priorEventsCount,
                templateEventsCount: response.data.templateEventsCount || 0,
                recurringEventsCount: response.data.recurringEventsCount || 0
            },
        });
    }
};

export const getEventsByTicketsAction = ({isInit}) => async (dispatch, getState) => {
    const {priorEvents} = getState().home.myTicketsData;

    const priorEventsIds = !isInit && priorEvents && priorEvents.length ? priorEvents.map(({event}) => event) : [];

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/home/tickets`, {priorEventsIds}, {crossDomain: true});

    if (response && response.data && response.data.status === 200) {
        dispatch({
            type: types.SET_HOME_PAGE_TICKETS,
            myTicketsData: {
                upcomingEvents: response.data.upcomingEvents,
                priorEvents: isInit ? response.data.priorEvents : [...priorEvents, ...response.data.priorEvents],
                upcomingEventsCount: response.data.upcomingEventsCount,
                priorEventsCount: response.data.priorEventsCount
            },
        });
    }
};

export const getMeetsAction = (eventId) => async () => {
    const response = await getInstanceAxios().get(`${config.NODE_API_URL}/home/get-meets/${eventId}`, {crossDomain: true});
    return response.data;
};

export const updateUserProfileInfoFromSettingsAction = (data) => async (dispatch, getState) => {
    const {profile, mode, eventId, isSelf, isCheckIn} = data;
    const {users: {currentUser}} = getState();
    const endpoint = isCheckIn ? `${config.NODE_API_URL}/users/check-in/update-user-profile-from-settings` : `${config.NODE_API_URL}/users/update-user-profile-from-settings`;
    const updateResponse = await getInstanceAxios().post(endpoint, {
        mode, eventId,
        user_id: profile.userId,
        email: profile.email,
        first_name: profile.firstName,
        last_name: profile.lastName,
        company: profile.company,
        title: profile.title,
        profile_photo: profile.profilePhoto,
        photo_name: profile.photoName,
        profile_color_hex: profile.profileColorHex,
        bio: profile.bio,
        age: profile.age,
        gender: profile.gender,
        location: profile.location,
        linkedin_link: profile.linkedinLink,
        twitter_link: profile.twitterLink
    });

    if (updateResponse && updateResponse.data && updateResponse.data.status === 200) {
        const {checkin: {eventRegistrations}} = getState();
        const newUserData = {
            email: profile.email,
            first_name: profile.firstName,
            last_name: profile.lastName,
            company: profile.company,
            title: profile.title,
            profile_photo: updateResponse.data.profilePhotoUrl,
            photo_name: profile.photoName,
            profile_color_hex: profile.profileColorHex,
            bio: profile.bio,
            age: profile.age,
            gender: profile.gender,
            location: profile.location,
        };

        if (isSelf) {
            dispatch(setCurrentUserAction({...currentUser, ...profile, profilePhoto: updateResponse.data.profilePhotoUrl}));
        }

        const updatedEventRegistrations = eventRegistrations
            .map(er => {
                if (er.bubble_user.user === profile.userId) {
                    return {...er, bubble_user: {...er.bubble_user, ...newUserData}}
                }
                return er;
            });

        dispatch(setEventRegistrationsAction(updatedEventRegistrations));
    }

    return updateResponse.data;
};

export const updateUserProfileInfoFromAttendeesProfileAction = (data) => async (dispatch, getState) => {
    const {profile, mode, eventId, userEventTagsSelected, ticket} = data;

    const updateResponse = await getInstanceAxios().post(`${config.NODE_API_URL}/users/update-user-profile-from-attendees-profile`, {
        content: profile.content,
        mode,
        ticket,
        event_id: eventId,
        event_tags: userEventTagsSelected,
        user_id: profile.userId,
        email: profile.email,
        first_name: profile.firstName,
        last_name: profile.lastName,
        company: profile.company,
        title: profile.title,
        profile_photo: profile.profilePhoto,
        photo_name: profile.photoName,
        profile_color_hex: profile.profileColorHex,
        bio: profile.bio,
        age: profile.age,
        gender: profile.gender,
        location: profile.location,
        twitter_link: profile.twitterLink,
        linkedin_link: profile.linkedinLink,
    });

    if (updateResponse && updateResponse.data && updateResponse.data.updatedEventRegistration && updateResponse.data.status === 200) {
        const {checkin: {eventRegistrations}} = getState();

        const updatedEventRegistrations = eventRegistrations
            .map(er => {
                if (er.bubble_user.user === profile.userId) {
                    return {...updateResponse.data.updatedEventRegistration}
                }
                return er;
            });

        dispatch(setOrganizerSidebarDataBubbleUserAction(
            {
                user: prepareUserData(updateResponse.data.updatedEventRegistration.bubble_user),
                tags: JSON.parse(updateResponse.data.updatedEventRegistration.event_tags || '{}')
            }
        ));
        dispatch(setEventRegistrationsAction(updatedEventRegistrations));
    }

    return updateResponse.data;
};

export const updateUserTimezoneAction = (userTimezone) => async (dispatch, getState) => {
    const {users: {currentUser}} = getState();
    const updateResponse = await getInstanceAxios().post(`${config.NODE_API_URL}/users/update-user-timezone`, {
        userTimezone
    }, {
        crossDomain: true
    });

    if (updateResponse && updateResponse.data && updateResponse.data.status === 200) {
        dispatch(setCurrentUserAction({...currentUser, userTimezone}));
    }

    return updateResponse.data;
};

export const deleteUserAction = () => async () => {
    const updateResponse = await getInstanceAxios().delete(`${config.NODE_API_URL}/users/delete-user`, {}, {
        crossDomain: true
    });

    if (updateResponse && updateResponse.data && updateResponse.data.status === 200) {
        logout();
    }
};

export const logoutOtherBrowsersAction = () => async (dispatch) => {
    dispatch({
        type: types.SET_INIT_LOGOUT_OTHER_BROWSERS,
        flag: true,
    });
    const response = await getInstanceAxios().delete(`${config.NODE_API_URL}/users/logout-other-browsers`, {}, {
        crossDomain: true
    });

    if (response && response.data && response.data.status === 200) {
        setSystemToken('jwt_token', response.data.jwtToken);
        if (response.data.user) setSystemToken('user_id', response.data.user.user);
        setTimeout(() => {
            dispatch({
                type: types.SET_INIT_LOGOUT_OTHER_BROWSERS,
                flag: false,
            });
        }, 30000)
    } else {
        dispatch({
            type: types.SET_INIT_LOGOUT_OTHER_BROWSERS,
            flag: false,
        });
    }

    return response.data;
};

export const addNewEmailAction = ({password, email}) => async (dispatch, getState) => {
    const {users: {currentUser}} = getState();
    const updateResponse = await getInstanceAxios().post(`${config.NODE_API_URL}/users/change-email`, {
        password, email
    }, {
        crossDomain: true
    });

    if (updateResponse && updateResponse.data && updateResponse.data.status === 200) {
        dispatch(setCurrentUserAction({...currentUser, email}));
    }

    return updateResponse.data;
};

export const changePasswordAction = ({password, newPassword}) => async () => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/users/change-password`, {
        password,
        newPassword
    }, {
        crossDomain: true
    });

    if (response.data.status !== 200) {
        return response.data;
    }

    return {status: 200};
};

export const resendInviteUserToAccountAction = ({userId, account}) => async () => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/resend-invite-user-to-account`, {
        userId, account
    }, {
        crossDomain: true
    });

    return response.data;
};

export const removeUserFromAccountAction = ({userId, account}) => async (dispatch, getState) => {
    const {home: {teams}} = getState();
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/remove-user-from-account`, {
        userId, account
    }, {
        crossDomain: true
    });

    if (response && response.data && response.data.status === 200) {
        dispatch({
            type: types.SET_TEAMS,
            teams: {[account]: teams[account].filter(({user_id}) => user_id !== userId)}
        });
    }

    return response.data;
};

export const inviteUserToAccountAction = ({
                                              firstName,
                                              lastName,
                                              role,
                                              email,
                                              account
                                          }) => async (dispatch, getState) => {
    const {home: {teams}} = getState();
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/invite-user-to-account`, {
        firstName, lastName, role, email, account
    }, {
        crossDomain: true
    });

    if (response && response.data && response.data.status === 200 && response.data.accountUserRole) {
        const targetTeams = teams[account] || [];
        if (response.data.updatedId) {
            const targetIndex = targetTeams.findIndex(({
                                                     user_id,
                                                     account_id
                                                 }) => user_id === response.data.updatedId && account_id === account);
            if (targetIndex !== -1) {
                targetTeams[targetIndex] = response.data.accountUserRole;
                dispatch({
                    type: types.SET_TEAMS,
                    teams: {[account]: targetTeams},
                });
                return response.data;
            }
        } else {
            dispatch({
                type: types.SET_TEAMS,
                teams: {[account]: [...teams[account], response.data.accountUserRole]}
            });
        }
    }
    return response.data;
};

export const changeAccountRoleAction = ({userId, role, account}) => async (dispatch, getState) => {
    const {home: {teams}} = getState();
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/change-account-role`, {
        userId, role, account
    });

    if (response && response.data && response.data.status === 200 && response.data.accountUserRole) {
        const targetTeams = teams[account] || [];
        if (response.data.updatedId) {
            const targetIndex = targetTeams.findIndex(({
                                                           user_id,
                                                           account_id
                                                       }) => user_id === response.data.updatedId && account_id === account);
            if (targetIndex !== -1) {
                targetTeams[targetIndex] = response.data.accountUserRole;
                dispatch({
                    type: types.SET_TEAMS,
                    teams: {[account]: targetTeams},
                });
                return response.data;
            }
        } else {
            dispatch({
                type: types.SET_TEAMS,
                teams: {[account]: [...teams[account], response.data.accountUserRole]}
            });
        }
    }
    return response.data;
};

export const transferOwnerAction = ({ firstName, lastName, email, account }) => async (dispatch, getState) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/transfer-owner`, {
        firstName, lastName, email, account
    }, {
        crossDomain: true
    });

    if (response && response.data && response.data.status === 200) {
        const {home: {accounts}} = getState();

        const indexAccount = accounts.findIndex(data => data.account === account);
        accounts[indexAccount] = response.data.account;
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts],
        });

        dispatch({
            type: types.SET_TEAMS,
            teams: {[account]: response.data.team}
        });
    }
    return response.data;
};

export const getAccountTeamAction = ({account}) => async (dispatch) => {
    const response = await getInstanceAxios().get(`${config.NODE_API_URL}/accounts/account-team/account/${account}`, {
        crossDomain: true
    });

    if (response && response.data && response.data.status === 200) {
        dispatch({
            type: types.SET_TEAMS,
            teams: {[account]: response.data.team},
        });
    }

    return response.data;
};

export const updateAccountBrandingAction = (account) => async (dispatch, getState) => {
    const {home: {accounts}} = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/update-account-branding`,
        account, {crossDomain: true}
    );

    if (response && response.data && response.data.status === 200) {
        const existIndexAccount = accounts.findIndex(data => data.account === account.account);

        accounts[existIndexAccount] = {
            ...accounts[existIndexAccount],
            ...response.data.currentAccount,
            accountCustomization: response.data.accountCustomization,
        };
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts],
        });
    }

    return response.data;
};

export const updateAccountSettings = (accountData) => async (dispatch, getState) => {
    const {home: {accounts}} = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/update-account-settings`,
        accountData
    );

    if (response && response.data && response.data.status === 200) {
        const existIndexAccount = accounts.findIndex(data => data.account === accountData.account);

        accounts[existIndexAccount] = {
            ...accounts[existIndexAccount],
            name: accountData.name,
            accountCustomization: response.data.accountCustomization,
        };
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts],
        });
    }

    return response.data;
};

export const updateAccountNameAction = ({account, name}) => async (dispatch, getState) => {
    const {home: {accounts}} = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/update-account-name`,
        {account: account.account, name }, {crossDomain: true}
    );

    if (response && response.data && response.data.status === 200) {
        const existIndexAccount = accounts.findIndex(data => data.account === account.account);
        accounts[existIndexAccount] = { ...account, name };
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts],
        });
    }

    return response.data;
};

export const updateAccountBrandingImage = ({ account, imageType, image }) => async (dispatch, getState) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/update-account-branding-image`,
        { account, imageType, image }, {crossDomain: true}
    );

    return response.data;
};

export const cancelSubscriptionAction = ({password, reason}) => async (dispatch, getState) => {
    const {home: {accounts}} = getState();
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/cancel-subscription`, {password, reason});

    if (!response || !response.data || !response.data.account || response.data.status !== 200) {
        console.error(response);
        if (!response || !response.data) {
            return {
                status: 401,
                message: 'Something went wrong.'
            }
        }
        return response.data;
    }

    const account = response.data.account;

    const existIndexAccount = accounts.findIndex(data => data.account === account.account);
    if (existIndexAccount === -1) {
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts, account],
        });
    } else {
        accounts[existIndexAccount] = account;
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts],
        });
    }

    return response.data;
};

export const resubscribeAction = ({planId}) => async (dispatch, getState) => {
    const {home: {accounts}} = getState();
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/stripe/resubscribe`, {planId});

    if (!response.data || !response.data.account || response.data.status !== 200) {
        console.error(response);
        return response;
    }

    const account = response.data.account;
    const existIndexAccount = accounts.findIndex(data => data.account === account.account);
    if (existIndexAccount === -1) {
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts, account],
        });
    } else {
        accounts[existIndexAccount] = account;
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts],
        });
    }

    return response.data;
};

export const setIsOpenPaymentStateModalAction = (flag) => async (dispatch) => {
    dispatch({
            type: types.SET_IS_OPEN_PAYMENT_MODAL,
            flag,
    });
};

export const setPaymentDataAction = (data) => async (dispatch) => {
    dispatch({
        type: types.SET_PAYMENT_DATA,
        data,
    });
};

export const generateAccountSecretTokenAction = (account) => async (dispatch, getState) => {
    const {home: {accounts}} = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/generate-secret-token`,
        {account: account.account }, {crossDomain: true}
    );

    if (response && response.data && response.data.status === 200) {
        const existIndexAccount = accounts.findIndex(data => data.account === account.account);
        const {secretToken} = response.data;
        accounts[existIndexAccount] = { ...account, secret_token: secretToken };
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts],
        });
    }

    return response.data;
};

export const saveOktaTokenAction = ({accountId, oktaClientID, oktaDomain}) => async (dispatch, getState) => {
    const {home: {accounts}} = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/save-okta-token`,
        {accountId, oktaClientID, oktaDomain }, {crossDomain: true}
    );

    if (response && response.data && response.data.status === 200) {
        const existIndexAccount = accounts.findIndex(data => data.account === accountId);
        accounts[existIndexAccount] = { ...accounts[existIndexAccount], oktaClientID, oktaDomain };
        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts],
        });
    }

    return response.data;
};

export const updateAccountPrivateTokenAction = ({account, apiKey, clientSecret}) => async (dispatch, getState) => {
    const {
        home: {accounts},
    } = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/accounts/update-private-token`,
        {account: account.account, apiKey, clientSecret }, {crossDomain: true}
    );

    if (response && response.data && response.data.status === 200) {
        const existIndexAccount = accounts.findIndex(data => data.account === account.account);
        accounts[existIndexAccount] = {
            ...account,
            eventbrite_api_key: apiKey,
            eventbrite_client_secret: clientSecret
        };

        dispatch({
            type: types.SET_HOME_PAGE_ACCOUNTS,
            accounts: [...accounts],
        });

        window.open(`https://www.eventbrite.com/oauth/authorize?response_type=code&client_id=${apiKey}&redirect_uri=${config.NODE_API_URL}/eventbrite/redirect?redirectData=${account.account}|`, '_blank');
   }

    return response.data;
};
