import React, { useState } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import { getDataOptions } from "utils/helpers";
import SettingsIcon from "assets/img/setting-icon.png";

import AboutDialog from "../../AboutDialog/AboutDialog";
import Settings from "../../Footer/Settings";
import { ControlItem } from "../../Footer/styled";


const SettingsWrapper = styled.div`
    display: ${pr => pr.visible ? "block" : "none"};
    position: absolute;
    bottom: 70px;
    left: 0;
`;

const ReactTooltipStyled1 = styled(ReactTooltip)`
    z-index: 1 !important;
    position: absolute;
    background-color: #444444 !important;
    color: #ffffff !important;
    border-radius: 15px !important;
    padding: 5px 12px !important;
`;

const Menu = () => {
    const [aboutOpen, setAboutOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const menuToggle = () => setMenuOpen(isOpen => !isOpen);

    return (
        <>
            <ReactTooltipStyled1
                arrowColor="transparent"
                id={"SettingsController"}
                place="top"
                effect="solid"
                className="tooltip1"
                multiline={true}
            >
                Settings
            </ReactTooltipStyled1>
            <ControlItem
                data-tip data-for={"SettingsController"}
                icon={SettingsIcon}
                data-trackable={
                    getDataOptions({
                        view: "SettingsController",
                        type: "button",
                        label: "SettingsIcon"
                    })
                }
                onClick={menuToggle}
            />

            <SettingsWrapper visible={menuOpen}>
                <Settings
                    setAboutOpen={setAboutOpen}
                    closeControl={() => setMenuOpen(false)}
                />
            </SettingsWrapper>

            <AboutDialog
                open={aboutOpen}
                onClose={() => {
                    setAboutOpen(false);
                    setMenuOpen(false);
                }}
            />
        </>
    );
};

export default Menu;
