import types from "../constans";

const initialState = {
    initLogoutOtherBrowser: false,
    myTicketsData: {
        upcomingEvents: [],
        priorEvents: [],
        upcomingEventsCount: 0,
        priorEventsCount: 0
    },
    events: {
        upcomingEvents: [],
        upcomingEventsCount: 0,
        priorEvents: [],
        priorEventsCount: 0,
        templateEvents: [],
        templateEventsCount: 0,
        recurringEvents: [],
        recurringEventsCount: 0
    },
    newEventTemplateId: '',
    accounts: [],
    teams: {},
    isOpenPaymentStateModal: false,
    paymentData: {
        type: '',
        date: '',
        planName: '',
        previousPlanName: '',
        amount: '',
        stripeCardBrand: '',
        stripeCardLast4: ''
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_HOME_PAGE_ACCOUNTS :
            return {
                ...state,
                accounts: action.accounts,
            };
        case types.SET_HOME_PAGE_EVENTS :
            return {
                ...state,
                events: action.events,
            };
        case types.SET_HOME_PAGE_TICKETS :
            return {
                ...state,
                myTicketsData: action.myTicketsData,
            };
        case types.SET_INIT_LOGOUT_OTHER_BROWSERS :
            return {
                ...state,
                initLogoutOtherBrowser: action.flag,
            };
        case types.SET_TEAMS:
            return {
                ...state,
                teams: {...state.teams, ...action.teams},
            };
        case types.SET_IS_OPEN_PAYMENT_MODAL:
            return {
                ...state,
                isOpenPaymentStateModal: action.flag,
            };
        case types.SET_PAYMENT_DATA:
            return {
                ...state,
                paymentData: action.data,
            };
        case types.SET_NEW_TEMPLATE_EVENT_ID:
            return {
                ...state,
                newEventTemplateId: action.eventId
            }

        default: return state
    }
}
